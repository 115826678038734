import { useNavigate } from 'react-router-dom'
import { Fab, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import { Add, EditNote } from '@mui/icons-material'
import { useContext, useRef } from 'react'
import { com } from '@eidu/form'
import EntityTypeRepository from '../../io/EntityTypeRepository'
import { EntityTypesContext } from '../../io/context/EntityTypes'
import ListItemButtonWithNavigation from '../../components/ListItemButtonWithNavigation'
import usePermittedActions from '../../io/usePermittedActions'
import PermittedGlobalAction = com.eidu.sharedlib.entity.permission.PermittedGlobalAction
import GlobalAction = com.eidu.sharedlib.entity.permission.GlobalAction
import PermissionError from './PermissionError'

type EntityTypesPageProps = { entityTypeRepository: EntityTypeRepository }

const EntityTypesPage = ({ entityTypeRepository }: EntityTypesPageProps) => {
  const navigate = useNavigate()

  const requested = useRef([new PermittedGlobalAction(GlobalAction.ManageEntityTypes)])
  const [permissions] = usePermittedActions(requested.current)

  const hasGlobalPermission = (action: GlobalAction) =>
    permissions?.some((permission) => permission.equals(new PermittedGlobalAction(action)))

  const entityTypes = entityTypeRepository.useSortedEntityTypes()

  return (
    (hasGlobalPermission(GlobalAction.ManageEntityTypes) && (
      <>
        <Fab
          color="primary"
          aria-label="Create entity type"
          onClick={() => navigate('../entities/new')}
          sx={{
            position: 'fixed',
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
          }}
        >
          <Add />
        </Fab>
        <Stack paddingX={3} paddingY={2}>
          <Typography variant="h4">Configure entity types</Typography>
          <List>
            {entityTypes &&
              entityTypes.map(({ name, id }) => (
                <ListItem key={`entity-type-${id}`}>
                  <ListItemButtonWithNavigation path={`/entities/${id.asString()}/configure`}>
                    <ListItemIcon>
                      <EditNote />
                    </ListItemIcon>
                    <ListItemText primary={name} />
                  </ListItemButtonWithNavigation>
                </ListItem>
              ))}
          </List>
        </Stack>
      </>
    )) ||
    PermissionError({ missingPermittedActions: [GlobalAction.ManageEntityTypes] })
  )
}

const EntityTypesPageRoute = () => {
  const entityTypeRepository = useContext(EntityTypesContext)
  return <EntityTypesPage entityTypeRepository={entityTypeRepository} />
}

export default EntityTypesPageRoute
