/* eslint-disable import/no-cycle */

import { Box, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { com, kotlin } from '@eidu/form'
import { useState } from 'react'
import DescriptionItem from './DescriptionItem'
import CheckboxItem from './CheckboxItem'
import GradeDropDownItem from './GradeDropDownItem'
import SchoolDropDownItem from './SchoolDropDownItem'
import StreamDropDownItem from './StreamDropDownItem'
import UserDropDownItem from './UserDropDownItem'
import EntityChoiceItem from './EntityChoiceItem'
import SingleLineItem from './SingleLineItem'
import MultiLineItem from './MultiLineItem'
import DateItem from './DateItem'
import LocationItem from './LocationItem'
import SectionItem from './SectionItem'
import MultiAnswerItem from './MultiAnswerItem'
import SingleChoiceItem from './SingleChoiceItem'
import MultipleChoiceItem from './MultipleChoiceItem'
import EntityChoice = com.eidu.sharedlib.form.definition.EntityChoice
import SingleChoiceStyle = com.eidu.sharedlib.form.definition.SingleChoiceStyle
import Checkbox = com.eidu.sharedlib.form.definition.Checkbox
import GradeDropDown = com.eidu.sharedlib.form.definition.GradeDropDown
import SchoolDropDown = com.eidu.sharedlib.form.definition.SchoolDropDown
import StreamDropDown = com.eidu.sharedlib.form.definition.StreamDropDown
import UserDropDown = com.eidu.sharedlib.form.definition.UserDropDown
import Description = com.eidu.sharedlib.form.definition.Description
import KtList = kotlin.collections.KtList
import Item = com.eidu.sharedlib.form.definition.Item
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import SingleLine = com.eidu.sharedlib.form.definition.SingleLine
import InputType = com.eidu.sharedlib.form.definition.InputType
import MultiLine = com.eidu.sharedlib.form.definition.MultiLine
import FormDate = com.eidu.sharedlib.form.definition.Date
import Location = com.eidu.sharedlib.form.definition.Location
import Section = com.eidu.sharedlib.form.definition.Section
import MultiAnswer = com.eidu.sharedlib.form.definition.MultiAnswer
import SingleChoice = com.eidu.sharedlib.form.definition.SingleChoice
import SingleChoiceOption = com.eidu.sharedlib.form.definition.Option
import MultipleChoice = com.eidu.sharedlib.form.definition.MultipleChoice
import MultipleChoiceOption = com.eidu.sharedlib.form.definition.MultipleChoiceOption
import { keyOf } from '../../util/withKey'

export type ItemType =
  | 'Description'
  | 'Checkbox'
  | 'EntityChoice'
  | 'SingleLine'
  | 'MultiLine'
  | 'Date'
  | 'Location'
  | 'Section'
  | 'MultiAnswer'
  | 'SingleChoice'
  | 'MultipleChoice'
  | 'GradeDropDown'
  | 'SchoolDropDown'
  | 'StreamDropDown'
  | 'UserDropDown'

type AddItemMenuProps = {
  anchorEl: HTMLElement | null
  onClose: () => void
  onSelect: (type: ItemType) => void
}

const AddItemMenu: React.FC<AddItemMenuProps> = ({ anchorEl, onClose, onSelect }) => {
  const handleSelect = (type: ItemType) => {
    onSelect(type)
    onClose()
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MenuItem onClick={() => handleSelect('Description')}>Description</MenuItem>
      <MenuItem onClick={() => handleSelect('Checkbox')}>Checkbox</MenuItem>
      <MenuItem onClick={() => handleSelect('SingleLine')}>Single Line</MenuItem>
      <MenuItem onClick={() => handleSelect('MultiLine')}>Multi-Line</MenuItem>
      <MenuItem onClick={() => handleSelect('Date')}>Date</MenuItem>
      <MenuItem onClick={() => handleSelect('Location')}>Location</MenuItem>
      <MenuItem onClick={() => handleSelect('EntityChoice')}>Entity Choice</MenuItem>
      <MenuItem onClick={() => handleSelect('MultiAnswer')}>Multi-Answer</MenuItem>
      <MenuItem onClick={() => handleSelect('SingleChoice')}>Single Choice</MenuItem>
      <MenuItem onClick={() => handleSelect('MultipleChoice')}>Multiple Choice</MenuItem>
      <MenuItem onClick={() => handleSelect('Section')}>Section</MenuItem>
      <MenuItem onClick={() => handleSelect('GradeDropDown')}>Grade Choice [Deprecated]</MenuItem>
      <MenuItem onClick={() => handleSelect('SchoolDropDown')}>School Choice [Deprecated]</MenuItem>
      <MenuItem onClick={() => handleSelect('StreamDropDown')}>Stream Choice [Deprecated]</MenuItem>
      <MenuItem onClick={() => handleSelect('UserDropDown')}>User Choice [Deprecated]</MenuItem>
    </Menu>
  )
}

type ItemListProps = {
  items: KtList<Item>
  onChange?: (newItems: KtList<Item>) => void
}

const ItemList: React.FC<ItemListProps> = ({ items, onChange }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1)

  const handleAddItem = (itemIndex: number, type: ItemType) => {
    const currentItems = items.asJsReadonlyArrayView()

    let newItem: Item

    switch (type) {
      case 'Description':
        newItem = new Description('New description')
        break
      case 'Checkbox':
        newItem = new Checkbox('New checkbox', `checkbox_${Date.now()}`)
        break
      case 'SingleLine':
        newItem = new SingleLine('New single line', `single_line_${Date.now()}`, InputType.Text, true)
        break
      case 'MultiLine':
        newItem = new MultiLine('New multi-line', `multi_line_${Date.now()}`, true)
        break
      case 'Date':
        newItem = new FormDate('New date', `date_${Date.now()}`, true, true)
        break
      case 'Location':
        newItem = new Location('New Location', `location_${Date.now()}`, true)
        break
      case 'EntityChoice':
        newItem = new EntityChoice(
          'New entity choice',
          `entity_choice_${Date.now()}`,
          EntitySelector.All,
          SingleChoiceStyle.DropDown,
          true
        )
        break
      case 'MultiAnswer':
        newItem = new MultiAnswer(
          'New multi answer',
          `multi_answer_${Date.now()}`,
          KtList.fromJsArray([]),
          true,
          'Add item',
          'Remove item'
        )
        break
      case 'SingleChoice':
        newItem = new SingleChoice(
          'New single choice',
          KtList.fromJsArray([new SingleChoiceOption('Option 1', KtList.fromJsArray([]))]),
          `single_choice_${Date.now()}`,
          SingleChoiceStyle.Radio,
          null,
          true
        )
        break
      case 'MultipleChoice':
        newItem = new MultipleChoice(
          'New multiple choice',
          KtList.fromJsArray([new MultipleChoiceOption(`option_1`, 'Option 1', KtList.fromJsArray([]))]),
          `multiple_choice_${Date.now()}`,
          true
        )
        break
      case 'Section':
        newItem = new Section('New section', `section_${Date.now()}`, KtList.fromJsArray([]))
        break
      case 'GradeDropDown':
        newItem = new GradeDropDown('New grade choice', KtList.fromJsArray([]), `grade_${Date.now()}`, true, null, null)
        break
      case 'SchoolDropDown':
        newItem = new SchoolDropDown('New school choice', `school_${Date.now()}`, true, null, null)
        break
      case 'StreamDropDown':
        newItem = new StreamDropDown('New stream choice', `stream_${Date.now()}`, true, null, null)
        break
      case 'UserDropDown':
        newItem = new UserDropDown('New user choice', `user_${Date.now()}`, KtList.fromJsArray([]), true, null, null)
        break
      default:
        return
    }

    const newItems = [...currentItems.slice(0, itemIndex + 1), newItem, ...currentItems.slice(itemIndex + 1)]
    onChange?.(KtList.fromJsArray(newItems))
  }

  const handleAddButtonClick = (event: React.MouseEvent<HTMLElement>, itemIndex: number) => {
    setMenuAnchorEl(event.currentTarget)
    setSelectedItemIndex(itemIndex)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
    setSelectedItemIndex(-1)
  }

  const handleDeleteItem = (itemIndex: number) => {
    const newItems = items.asJsReadonlyArrayView().filter((_, i) => i !== itemIndex)
    onChange?.(KtList.fromJsArray(newItems))
  }

  const handleChangeItem = (itemIndex: number, newItem: Item) => {
    const newItems = [...items.asJsReadonlyArrayView()]
    newItems[itemIndex] = newItem
    onChange?.(KtList.fromJsArray(newItems))
  }

  return (
    <Stack spacing={1}>
      {onChange && (
        <IconButton
          size="small"
          onClick={(e) => handleAddButtonClick(e, -1)}
          sx={{ alignSelf: 'center' }}
          aria-label="add item at start"
        >
          <AddIcon />
        </IconButton>
      )}

      {items.asJsReadonlyArrayView().map((item, itemIndex) => (
        <Stack key={keyOf(item)} spacing={1}>
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="caption"
                sx={{
                  color: 'text.secondary',
                  mb: 0.5,
                  display: 'block',
                }}
              >
                {item instanceof Description && 'Description'}
                {item instanceof Checkbox && 'Checkbox'}
                {item instanceof SingleLine && 'Single Line'}
                {item instanceof MultiLine && 'Multi-Line'}
                {item instanceof FormDate && 'Date'}
                {item instanceof Location && 'Location'}
                {item instanceof EntityChoice && 'Entity Choice'}
                {item instanceof MultiAnswer && 'Multi-Answer'}
                {item instanceof SingleChoice && 'Single Choice'}
                {item instanceof MultipleChoice && 'Multiple Choice'}
                {item instanceof Section && 'Section'}
                {item instanceof GradeDropDown && 'Grade Choice'}
                {item instanceof SchoolDropDown && 'School Choice'}
                {item instanceof StreamDropDown && 'Stream Choice'}
                {item instanceof UserDropDown && 'User Choice'}
              </Typography>
              {item instanceof Description && (
                <DescriptionItem
                  item={item}
                  onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))}
                />
              )}
              {item instanceof Checkbox && (
                <CheckboxItem item={item} onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))} />
              )}
              {item instanceof SingleLine && (
                <SingleLineItem
                  item={item}
                  onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))}
                />
              )}
              {item instanceof MultiLine && (
                <MultiLineItem item={item} onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))} />
              )}
              {item instanceof FormDate && (
                <DateItem item={item} onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))} />
              )}
              {item instanceof Location && (
                <LocationItem item={item} onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))} />
              )}
              {item instanceof EntityChoice && (
                <EntityChoiceItem
                  item={item}
                  onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))}
                />
              )}
              {item instanceof MultiAnswer && (
                <MultiAnswerItem
                  item={item}
                  onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))}
                />
              )}
              {item instanceof SingleChoice && (
                <SingleChoiceItem
                  item={item}
                  onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))}
                />
              )}
              {item instanceof MultipleChoice && (
                <MultipleChoiceItem
                  item={item}
                  onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))}
                />
              )}
              {item instanceof Section && (
                <SectionItem item={item} onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))} />
              )}
              {item instanceof GradeDropDown && (
                <GradeDropDownItem
                  item={item}
                  onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))}
                />
              )}
              {item instanceof SchoolDropDown && (
                <SchoolDropDownItem
                  item={item}
                  onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))}
                />
              )}
              {item instanceof StreamDropDown && (
                <StreamDropDownItem
                  item={item}
                  onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))}
                />
              )}
              {item instanceof UserDropDown && (
                <UserDropDownItem
                  item={item}
                  onChange={onChange && ((newItem) => handleChangeItem(itemIndex, newItem))}
                />
              )}
            </Box>
            {onChange && (
              <IconButton size="small" onClick={() => handleDeleteItem(itemIndex)} sx={{ mt: 3 }}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Stack>
          {onChange && (
            <IconButton
              size="small"
              onClick={(e) => handleAddButtonClick(e, itemIndex)}
              sx={{ alignSelf: 'center' }}
              aria-label={`add item after index ${itemIndex}`}
            >
              <AddIcon />
            </IconButton>
          )}
        </Stack>
      ))}
      <AddItemMenu
        anchorEl={menuAnchorEl}
        onClose={handleMenuClose}
        onSelect={(type) => {
          handleAddItem(selectedItemIndex, type)
          handleMenuClose()
        }}
      />
    </Stack>
  )
}

export default ItemList
