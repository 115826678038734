import { AppBar, Button, Card, Divider, Stack, Toolbar } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { com } from '@eidu/form'
import EntityReference from './entity/EntityReference'
import EntityWithLabelAndRelated from '../domain/entity/EntityWithLabelAndRelated'
import { EntityTypesContext } from '../io/context/EntityTypes'
import useEntityRepository from '../io/useEntityRepository'
import AuthenticationContext from '../api/authorization/AuthenticationContext'
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import entityIdFromString = com.eidu.sharedlib.entity.entityIdFromString

export type TopAppBarProps = {
  authContext: AuthenticationContext | undefined
  signOut?: () => void
}

const TopAppBar = ({ authContext, signOut }: TopAppBarProps) => {
  const entityTypes = useContext(EntityTypesContext).useAll()
  const entityRepository = useEntityRepository(
    { typeId: undefined, types: entityTypes ?? new Map<EntityTypeId, EntityType>() },
    [authContext, entityTypes]
  )

  const [userEntity, setUserEntity] = useState<EntityWithLabelAndRelated>()

  useEffect(() => {
    if (authContext)
      entityRepository.get(entityIdFromString(authContext?.userEntityId)).then((entity) => {
        if (entity !== null) setUserEntity(entity)
      })
  }, [authContext, entityRepository])

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'left', width: '100%' }}>
            <img src="/logo192.png" className="appBarIcon" alt="" />
          </Stack>
          <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'right', width: '100%', padding: 1 }}>
            {userEntity && (
              <Card variant="outlined" sx={{ padding: 1 }}>
                <EntityReference
                  id={userEntity.entity.id}
                  label={userEntity.primaryLabelText}
                  typeId={userEntity.entity.typeId}
                />
              </Card>
            )}
            {userEntity && signOut && <Divider orientation="vertical" />}
            {signOut && (
              <Button variant="text" color="inherit" onClick={signOut}>
                Log out
              </Button>
            )}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default TopAppBar
