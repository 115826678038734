import { useContext, useEffect, useMemo, useState } from 'react'
import { Stack } from '@mui/material'
import { com } from '@eidu/form'
import FieldSingleSelect from './FieldSingleSelect'
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeKind = com.eidu.sharedlib.entity.type.EntityTypeKind
import {
  entityTypeIdByFieldIdOrFirst,
  findFieldByIdOrFirst,
  fieldsOfEntityType,
} from '../../../util/EntityTypeExtensions'
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import { EntityTypesContext } from '../../../io/context/EntityTypes'
import { EntityTypeSingleSelect } from './EntityTypeSingleSelect'

type FieldValueEqualsInputProps = {
  entityTypeId: EntityTypeId
  allEntityTypes: readonly EntityType[]
  selector: EntitySelector.FieldValueEqualsUserFieldValue | undefined
  setSelector: (selector: EntitySelector.FieldValueEqualsUserFieldValue) => void
  disabled: boolean
}

const FieldValueEqualsUserFieldValueInput = ({
  entityTypeId,
  allEntityTypes,
  selector,
  setSelector,
  disabled,
}: FieldValueEqualsInputProps) => {
  const userEntityTypes = allEntityTypes.filter((entityType) => entityType.kind === EntityTypeKind.User)
  const entityTypeRepository = useContext(EntityTypesContext)
  const localEntityType = entityTypeRepository.useItem(entityTypeId)

  const [localFieldId, setLocalFieldId] = useState<FieldId | undefined>(selector?.fieldId)
  const [localUserFieldId, setLocalUserFieldId] = useState<FieldId | undefined>(selector?.userFieldId)
  const [localUserEntityTypeId, setLocalUserEntityTypeId] = useState<EntityTypeId | undefined>(
    entityTypeIdByFieldIdOrFirst(userEntityTypes, localUserFieldId)
  )

  const localUserEntityType = localUserEntityTypeId && entityTypeRepository.useItem(localUserEntityTypeId)

  const field = useMemo(
    () => findFieldByIdOrFirst(localEntityType ?? undefined, localFieldId),
    [localEntityType, localFieldId]
  )
  const userField = useMemo(
    () => findFieldByIdOrFirst(localUserEntityType ?? undefined, localUserFieldId),
    [localUserEntityType, localUserFieldId]
  )
  useEffect(() => {
    if (field && userField) {
      setSelector(new EntitySelector.FieldValueEqualsUserFieldValue(field.id, userField.id))
    }
  }, [localFieldId, localUserFieldId])

  return (
    localEntityType &&
    localUserEntityType && (
      <Stack direction="row" spacing={2}>
        <FieldSingleSelect
          label="Field"
          options={fieldsOfEntityType(localEntityType)}
          selected={field}
          entityType={localEntityType}
          setSelected={(newField) => setLocalFieldId(newField.id)}
          disabled={disabled}
        />
        <EntityTypeSingleSelect
          label="User type"
          allOption={false}
          options={userEntityTypes}
          entityTypeId={localUserEntityTypeId}
          setEntityTypeId={(newEntityTypeId) => {
            if (newEntityTypeId) setLocalUserEntityTypeId(newEntityTypeId)
          }}
        />
        <FieldSingleSelect
          label="User field"
          options={fieldsOfEntityType(localUserEntityType)}
          entityType={localUserEntityType}
          selected={userField}
          setSelected={(newField) => setLocalUserFieldId(newField.id)}
          disabled={disabled}
        />
      </Stack>
    )
  )
}

export default FieldValueEqualsUserFieldValueInput
