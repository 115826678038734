/* eslint-disable import/no-cycle */

import {
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  IconButton,
  Box,
  Typography,
  InputLabel,
  FormControl,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { com, kotlin } from '@eidu/form'
import SingleChoice = com.eidu.sharedlib.form.definition.SingleChoice
import SingleChoiceStyle = com.eidu.sharedlib.form.definition.SingleChoiceStyle
import Option = com.eidu.sharedlib.form.definition.Option
import Item = com.eidu.sharedlib.form.definition.Item
import KtList = kotlin.collections.KtList
import ItemList from './ItemList'
import { keyOf, withKey } from '../../util/withKey'

type SingleChoiceItemProps = {
  item: SingleChoice
  onChange?: (item: SingleChoice) => void
}

const SingleChoiceItem: React.FC<SingleChoiceItemProps> = ({ item, onChange }) => {
  const handleOptionTextChange = (index: number, text: string) => {
    const newOptions = [...item.options.asJsReadonlyArrayView()]
    newOptions[index] = withKey(new Option(text, newOptions[index].items), newOptions[index])
    onChange?.(
      withKey(
        item.copy(item.prompt, KtList.fromJsArray(newOptions), item.key, item.style, item.default, item.required),
        item
      )
    )
  }

  const handleOptionItemsChange = (index: number, newItems: KtList<Item>) => {
    const newOptions = [...item.options.asJsReadonlyArrayView()]
    newOptions[index] = new Option(newOptions[index].text, newItems)
    onChange?.(
      withKey(
        item.copy(item.prompt, KtList.fromJsArray(newOptions), item.key, item.style, item.default, item.required),
        item
      )
    )
  }

  const addOption = () => {
    const newOptions = [...item.options.asJsReadonlyArrayView(), new Option('New option', KtList.fromJsArray([]))]
    onChange?.(
      withKey(
        item.copy(item.prompt, KtList.fromJsArray(newOptions), item.key, item.style, item.default, item.required),
        item
      )
    )
  }

  const removeOption = (index: number) => {
    const newOptions = item.options.asJsReadonlyArrayView().filter((_, i) => i !== index)
    const newDefault =
      item.default !== null && item.default !== undefined
        ? item.default >= index
          ? Math.max(0, item.default - 1)
          : item.default
        : null
    onChange?.(
      withKey(
        item.copy(item.prompt, KtList.fromJsArray(newOptions), item.key, item.style, newDefault, item.required),
        item
      )
    )
  }

  return (
    <Stack
      spacing={2}
      sx={{ p: 1, backgroundColor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}
    >
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <TextField
          fullWidth
          label="Prompt"
          value={item.prompt}
          onChange={(e) =>
            onChange?.(
              withKey(item.copy(e.target.value, item.options, item.key, item.style, item.default, item.required), item)
            )
          }
          variant="standard"
          disabled={!onChange}
        />
        <TextField
          label="Key"
          value={item.key}
          onChange={(e) =>
            onChange?.(
              withKey(
                item.copy(item.prompt, item.options, e.target.value, item.style, item.default, item.required),
                item
              )
            )
          }
          variant="standard"
          disabled={!onChange}
        />
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center">
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
          <InputLabel>Style</InputLabel>
          <Select
            value={item.style.name}
            onChange={(e) =>
              onChange?.(
                withKey(
                  item.copy(
                    item.prompt,
                    item.options,
                    item.key,
                    SingleChoiceStyle.valueOf(e.target.value),
                    item.default,
                    item.required
                  ),
                  item
                )
              )
            }
            disabled={!onChange}
          >
            <MenuItem value={SingleChoiceStyle.Radio.name}>Radio</MenuItem>
            <MenuItem value={SingleChoiceStyle.DropDown.name}>Dropdown</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
          <InputLabel>Default</InputLabel>
          <Select
            value={item.default ?? 'None'}
            onChange={(e) =>
              onChange?.(
                withKey(
                  item.copy(
                    item.prompt,
                    item.options,
                    item.key,
                    item.style,
                    e.target.value === 'None' ? null : Number(e.target.value),
                    item.required
                  ),
                  item
                )
              )
            }
            variant="standard"
            disabled={!onChange}
          >
            <MenuItem value="None">(None)</MenuItem>
            {item.options.asJsReadonlyArrayView().map((option, index) => (
              <MenuItem key={keyOf(option)} value={index}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={item.required}
              onChange={(e) =>
                onChange?.(
                  withKey(
                    item.copy(item.prompt, item.options, item.key, item.style, item.default, e.target.checked),
                    item
                  )
                )
              }
              disabled={!onChange}
            />
          }
          label="Required"
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="subtitle2">Options</Typography>
        {item.options.asJsReadonlyArrayView().map((option, index) => (
          <Box key={keyOf(option)} sx={{ p: 1, borderRadius: 1, border: '1px solid', borderColor: 'divider' }}>
            <Stack spacing={1}>
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  label={`Option ${index + 1}`}
                  value={option.text}
                  onChange={(e) => handleOptionTextChange(index, e.target.value)}
                  variant="standard"
                  disabled={!onChange}
                />
                {onChange && (
                  <IconButton size="small" onClick={() => removeOption(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
              <Box sx={{ bgcolor: 'grey.50', borderRadius: 1 }} padding={1}>
                <ItemList
                  items={option.items}
                  onChange={onChange && ((newItems) => handleOptionItemsChange(index, newItems))}
                />
              </Box>
            </Stack>
          </Box>
        ))}
        {onChange && (
          <IconButton size="small" onClick={addOption} sx={{ alignSelf: 'center' }}>
            <AddIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}

export default SingleChoiceItem
