import { com, kotlin } from '@eidu/form'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import serializeToJsonString = com.eidu.sharedlib.entity.util.serializeToJsonString
import PatchEntitiesRequest = com.eidu.sharedlib.entity.api.entities.PatchEntitiesRequest
import EntityId = com.eidu.sharedlib.entity.EntityId
import KtMap = kotlin.collections.KtMap
import PatchEntityRequest = com.eidu.sharedlib.entity.api.entities.PatchEntityRequest
import Patch from './Patch'

type PatchEntitiesParameters = {
  patchesByEntityId: Map<EntityId, Patch>
  authContext: AuthenticationContext
}

const patchEntities = async ({ patchesByEntityId, authContext }: PatchEntitiesParameters) => {
  const response = await fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/entities`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
    body: serializeToJsonString(
      new PatchEntitiesRequest(
        KtMap.fromJsMap(
          new Map(
            [...patchesByEntityId].map(([id, patch]) => [
              id,
              new PatchEntityRequest(
                patch.valuesByFieldId ? KtMap.fromJsMap(patch.valuesByFieldId) : null,
                patch.links ? KtMap.fromJsMap(patch.links) : null
              ),
            ])
          )
        )
      )
    ),
    method: 'PATCH',
  })
  if (response.status !== 200) throw Error(`Unexpected response code ${response.status} from patchEntities endpoint`)
}

export default patchEntities
