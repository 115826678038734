import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { com } from '@eidu/form'
import FieldValueEqualsInput from './FieldValueEqualsInput'
import EntitySelectorTypeInput, { SupportedEntitySelectorType } from './EntitySelectorTypeInput'
import FieldValueEqualsUserFieldValueInput from './FieldValueEqualsUserFieldValueInput'
import { findEntityTypeByFieldId } from '../../../util/EntityTypeExtensions'
import { EntityTypeSingleSelect } from './EntityTypeSingleSelect'
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import FieldValueEqualsUserEntityId from './FieldValueEqualsUserEntityIdInput'

type EntitySelectorInputProps = {
  entityTypes: readonly EntityType[]
  entitySelector: EntitySelector
  setEntitySelector?: (selector: EntitySelector) => void
  disabled?: boolean
}

const EntitySelectorInput: React.FC<EntitySelectorInputProps> = ({
  entityTypes,
  entitySelector,
  setEntitySelector,
  disabled,
}) => {
  const isDisabled = disabled || !setEntitySelector

  const [entitySelectorType, setEntitySelectorType] = useState<SupportedEntitySelectorType>()
  const [entityTypeId, setEntityTypeId] = useState<EntityTypeId | undefined>()

  useEffect(() => {
    if (entitySelector instanceof EntitySelector.FieldValueEqualsUserEntityId) {
      setEntitySelectorType(SupportedEntitySelectorType.FieldValueEqualsUserEntityId)
      const newEntityTypeId = findEntityTypeByFieldId(entityTypes, entitySelector.fieldId)
      if (newEntityTypeId) setEntityTypeId(newEntityTypeId.id)
    } else if (entitySelector instanceof EntitySelector.FieldValueEqualsUserFieldValue) {
      setEntitySelectorType(SupportedEntitySelectorType.FieldValueEqualsUserFieldValue)
      const newEntityTypeId = findEntityTypeByFieldId(entityTypes, entitySelector.fieldId)
      if (newEntityTypeId) setEntityTypeId(newEntityTypeId.id)
    } else if (entitySelector instanceof EntitySelector.FieldValueEquals) {
      setEntitySelectorType(SupportedEntitySelectorType.FieldValueEquals)
      const newEntityTypeId = findEntityTypeByFieldId(entityTypes, entitySelector.fieldId)
      if (newEntityTypeId) setEntityTypeId(newEntityTypeId.id)
    } else if (entitySelector instanceof EntitySelector.OfType) {
      setEntitySelectorType(SupportedEntitySelectorType.All)
      setEntityTypeId(entitySelector.typeId)
    } else {
      setEntityTypeId(undefined)
      setEntitySelectorType(undefined)
    }
  }, [entitySelector])

  return (
    <Stack spacing={1}>
      {entityTypes && (
        <EntityTypeSingleSelect
          label="Type"
          options={entityTypes}
          allOption
          entityTypeId={entityTypeId}
          setEntityTypeId={(newEntityTypeId) => {
            if (newEntityTypeId === undefined) setEntitySelector?.(EntitySelector.All)
            else setEntitySelector?.(new EntitySelector.OfType(newEntityTypeId))
          }}
          disabled={isDisabled}
        />
      )}
      {entityTypeId && entitySelectorType !== undefined && (
        <Stack direction="row" spacing={1} sx={{ backgroundColor: '#f5f5f5', borderRadius: 1 }}>
          <EntitySelectorTypeInput
            optionsWithLabels={
              new Map([
                [SupportedEntitySelectorType.All, 'All'],
                [SupportedEntitySelectorType.FieldValueEquals, 'Field value equals'],
                [SupportedEntitySelectorType.FieldValueEqualsUserFieldValue, 'Field value equals user field value'],
                [SupportedEntitySelectorType.FieldValueEqualsUserEntityId, 'Field value is user'],
              ])
            }
            selected={entitySelectorType}
            setSelected={(newType) => {
              if (newType === SupportedEntitySelectorType.All)
                setEntitySelector?.(new EntitySelector.OfType(entityTypeId))
              else setEntitySelectorType(newType)
            }}
            disabled={isDisabled}
          />
          {entitySelectorType === SupportedEntitySelectorType.FieldValueEquals && (
            <FieldValueEqualsInput
              disabled={isDisabled}
              entityTypeId={entityTypeId}
              selector={entitySelector as EntitySelector.FieldValueEquals}
              setSelector={(newSelector) => {
                if (newSelector) setEntitySelector?.(newSelector)
              }}
            />
          )}
          {entitySelectorType === SupportedEntitySelectorType.FieldValueEqualsUserFieldValue && (
            <FieldValueEqualsUserFieldValueInput
              entityTypeId={entityTypeId}
              allEntityTypes={entityTypes}
              selector={entitySelector as EntitySelector.FieldValueEqualsUserFieldValue}
              setSelector={(newSelector) => {
                if (newSelector) setEntitySelector?.(newSelector)
              }}
              disabled={isDisabled}
            />
          )}
          {entitySelectorType === SupportedEntitySelectorType.FieldValueEqualsUserEntityId && (
            <FieldValueEqualsUserEntityId
              entityTypeId={entityTypeId}
              selector={entitySelector as EntitySelector.FieldValueEqualsUserEntityId}
              setSelector={(newSelector) => {
                if (newSelector) setEntitySelector?.(newSelector)
              }}
              disabled={isDisabled}
            />
          )}
        </Stack>
      )}
    </Stack>
  )
}

export default EntitySelectorInput
