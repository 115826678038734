import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { com } from '@eidu/form'
import EntityTypeKind = com.eidu.sharedlib.entity.type.EntityTypeKind

export type KindInputProps = {
  kind?: EntityTypeKind
  setKind: (kind: EntityTypeKind) => void
  editable: boolean
}

const KindInput = ({ kind, setKind, editable }: KindInputProps) => {
  const handleChange = (event: SelectChangeEvent) => setKind(EntityTypeKind.valueOf(event.target.value))

  return (
    <Select value={kind?.name} onChange={handleChange} disabled={!editable} variant="outlined">
      {EntityTypeKind.values().map((value) => (
        <MenuItem key={value.name} value={value.name}>
          {value.name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default KindInput
