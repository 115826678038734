import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Alert, Box, Button, Collapse, Fab, Stack, Typography } from '@mui/material'
import { com } from '@eidu/form'
import { useGridApiRef } from '@mui/x-data-grid'
import { GridApiCommunity } from '@mui/x-data-grid/internals'
import { Add } from '@mui/icons-material'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import LoadingOverlay from '../../components/LoadingOverlay'
import exportEntitiesOfType from '../../api/entity/data/exportEntitiesOfType'
import PaginationModel from '../../util/pagination/PaginationModel'
import pageSizeOptions from '../../util/pagination/pageSizeOptions'
import EntityTypeRepository from '../../io/EntityTypeRepository'
import { EntityTypesContext } from '../../io/context/EntityTypes'
import ListEntityDataGrid from '../../components/entity/ListEntityDataGrid'
import { isNotNull } from '../../util/predicates'
import useEntityRepository from '../../io/useEntityRepository'
import EntityWithLabelAndRelated from '../../domain/entity/EntityWithLabelAndRelated'
import EntityRepository from '../../io/EntityRepository'
import useDebounced from '../../ui/debounce/useDebounced'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import entityTypeIdFromString = com.eidu.sharedlib.entity.type.entityTypeIdFromString
import { createAuthContext } from '../../api/authorization/AuthenticationContext'
import { requireNotUndefinedOrNull } from '../../util/require'

type ListPageContentProps = {
  onImport: () => void
  onNew: () => void
  onDownloadCsv: () => void
  entityType: EntityType
  entities: readonly EntityWithLabelAndRelated[]
  entityRepository: EntityRepository
  relatedTypes: ReadonlyMap<EntityTypeId, EntityType>
  paginationModel: PaginationModel
  setPaginationModel: (paginationModel: PaginationModel) => void
  searchQuery: string
  setSearchQuery: (searchQuery: string) => void
  rowCount: number
  loading: boolean
  apiRef?: React.MutableRefObject<GridApiCommunity>
}

export const ListPageContent = ({
  onImport,
  onNew,
  onDownloadCsv,
  entityType,
  entities,
  entityRepository,
  relatedTypes,
  paginationModel,
  setPaginationModel,
  searchQuery,
  setSearchQuery,
  rowCount,
  loading,
  apiRef,
}: ListPageContentProps) => (
  <>
    <Fab
      color="primary"
      aria-label={`Create ${entityType.name}`}
      onClick={onNew}
      sx={{
        position: 'fixed',
        bottom: (theme) => theme.spacing(2),
        right: (theme) => theme.spacing(2),
      }}
    >
      <Add />
    </Fab>
    <Stack padding={3}>
      {entityType && (
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography variant="h4">{entityType.name}</Typography>
          <Stack direction="row" justifyContent="flex-end">
            <Box>
              <Button color="primary" onClick={onImport}>
                Import
              </Button>
              <Button color="primary" onClick={onDownloadCsv}>
                Export
              </Button>
            </Box>
          </Stack>
        </Stack>
      )}
      {entityType && entities && (
        <Stack>
          <Box sx={{ paddingY: 2 }}>
            <ListEntityDataGrid
              navigate={useNavigate()}
              entities={entities}
              relatedTypes={relatedTypes}
              entityType={entityType}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              rowCount={rowCount}
              loading={loading}
              apiRef={apiRef}
              entityRepository={entityRepository}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  </>
)

export type ReadyListPageProps = {
  entityType: EntityType
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>
}

const ReadyListPage = ({ entityType, entityTypes }: ReadyListPageProps) => {
  const navigate = useNavigate()
  const [paginationModel, setPaginationModel] = useState({ pageSize: pageSizeOptions.small, page: 0 })
  const [errorPreparingExport, setErrorPreparingExport] = useState<string | undefined>(undefined)
  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedSearchQuery] = useDebounced(searchQuery)

  const authContext = createAuthContext(useContext<IAuthContext>(AuthContext))

  const prepareCsvDownload = () => {
    exportEntitiesOfType({ id: entityType.id, authContext: requireNotUndefinedOrNull(authContext) })
      .then((jobId) => {
        const args = new URLSearchParams({ jobId }).toString()
        navigate(`/export?${args}`)
      })
      .catch((err) => {
        setErrorPreparingExport(`Failed to prepare export: ${err}`)
      })
  }

  const entityRepository = useEntityRepository(
    {
      typeId: entityType.id,
      types: entityTypes,
      pageSize: paginationModel.pageSize,
      searchQuery: debouncedSearchQuery,
    },
    [entityType.id.asString(), entityTypes.size, paginationModel.pageSize]
  )

  const page = entityRepository.usePage(paginationModel.page, [entityRepository])
  const entitiesOnPage = Array.from(page.values()).filter(isNotNull)

  const numEntities = entityRepository.useNumItems([entityRepository])

  // Important: all use* need to run, so don't replace this by anything using short-circuiting!
  const error = [entityRepository.useError(), errorPreparingExport].find((it) => !!it)?.let((it) => String(it))
  const loading = [entityRepository.useLoading()].some((it) => it)

  const apiRef = useGridApiRef()
  useEffect(() => {
    if (!loading && entitiesOnPage && apiRef && apiRef.current && apiRef.current.autosizeColumns) {
      setTimeout(() => {
        apiRef.current
          .autosizeColumns({
            includeHeaders: true,
            includeOutliers: true,
            outliersFactor: 1,
            expand: false,
          })
          .then()
      }, 0)
    }
  }, [loading, entitiesOnPage, JSON.stringify(paginationModel)])

  useEffect(() => {
    setSearchQuery('')
  }, [entityType.id])

  return (
    <Stack>
      <Collapse in={!!error}>
        <Alert
          variant="filled"
          severity="error"
          sx={{ margin: 2 }}
          action={
            <Button color="inherit" size="small" onClick={() => window.location.reload()}>
              Reload
            </Button>
          }
        >
          <Box>{error}</Box>
          <Box>Please reload the page and try again.</Box>
        </Alert>
      </Collapse>
      <ListPageContent
        onImport={() => {
          navigate('import')
        }}
        onNew={() => {
          navigate('new')
        }}
        onDownloadCsv={prepareCsvDownload}
        entityType={entityType}
        entities={entitiesOnPage}
        entityRepository={entityRepository}
        relatedTypes={entityTypes}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        searchQuery={searchQuery}
        setSearchQuery={(query) => {
          setSearchQuery(query)
        }}
        rowCount={numEntities || 0}
        loading={loading}
        apiRef={apiRef}
      />
      <LoadingOverlay isOpen={!(error || entityType || entityRepository)} />
    </Stack>
  )
}

export type ListPageProps = {
  entityTypeId: EntityTypeId
  entityTypeRepository: EntityTypeRepository
}

const ListPage = ({ entityTypeId, entityTypeRepository }: ListPageProps) => {
  const entityTypes = entityTypeRepository.useAll()
  const entityType = entityTypeRepository.useItem(entityTypeId)
  const error = entityTypeRepository.useError()?.let((it) => String(it))

  return (
    <>
      {entityType && entityTypes && <ReadyListPage entityType={entityType} entityTypes={entityTypes} />}
      <LoadingOverlay isOpen={!(error || entityType)} />
    </>
  )
}

const ListPageRoute = () => {
  const params = useParams()
  const entityTypeRepository = useContext(EntityTypesContext)

  if (params.entityTypeId)
    return (
      <ListPage
        entityTypeId={entityTypeIdFromString(params.entityTypeId)}
        entityTypeRepository={entityTypeRepository}
      />
    )
  else return <Typography sx={{ padding: 3 }}>Unknown state</Typography>
}

export default ListPageRoute
