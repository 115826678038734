/* eslint-disable import/no-cycle */

import { Stack, TextField, FormControlLabel, Switch, IconButton, Box, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { com, kotlin } from '@eidu/form'
import MultipleChoice = com.eidu.sharedlib.form.definition.MultipleChoice
import MultipleChoiceOption = com.eidu.sharedlib.form.definition.MultipleChoiceOption
import Item = com.eidu.sharedlib.form.definition.Item
import KtList = kotlin.collections.KtList
import ItemList from './ItemList'
import { keyOf, withKey } from '../../util/withKey'

type MultipleChoiceItemProps = {
  item: MultipleChoice
  onChange?: (item: MultipleChoice) => void
}

const MultipleChoiceItem: React.FC<MultipleChoiceItemProps> = ({ item, onChange }) => {
  const handleOptionChange = (index: number, key: string, text: string) => {
    const newOptions = [...item.options.asJsReadonlyArrayView()]
    newOptions[index] = withKey(new MultipleChoiceOption(key, text, newOptions[index].items), newOptions[index])
    onChange?.(withKey(item.copy(item.prompt, KtList.fromJsArray(newOptions), item.key, item.required), item))
  }

  const handleOptionItemsChange = (index: number, newItems: KtList<Item>) => {
    const newOptions = [...item.options.asJsReadonlyArrayView()]
    newOptions[index] = withKey(
      new MultipleChoiceOption(newOptions[index].key, newOptions[index].text, newItems),
      newOptions[index]
    )
    onChange?.(withKey(item.copy(item.prompt, KtList.fromJsArray(newOptions), item.key, item.required), item))
  }

  const addOption = () => {
    const newOptions = [
      ...item.options.asJsReadonlyArrayView(),
      new MultipleChoiceOption(`option_${Date.now()}`, 'New option', KtList.fromJsArray([])),
    ]
    onChange?.(withKey(item.copy(item.prompt, KtList.fromJsArray(newOptions), item.key, item.required), item))
  }

  const removeOption = (index: number) => {
    const newOptions = item.options.asJsReadonlyArrayView().filter((_, i) => i !== index)
    onChange?.(withKey(item.copy(item.prompt, KtList.fromJsArray(newOptions), item.key, item.required), item))
  }

  return (
    <Stack
      spacing={2}
      sx={{ p: 1, backgroundColor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}
    >
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <TextField
          fullWidth
          label="Prompt"
          value={item.prompt}
          onChange={(e) => onChange?.(withKey(item.copy(e.target.value, item.options, item.key, item.required), item))}
          variant="standard"
          disabled={!onChange}
        />
        <TextField
          label="Key"
          value={item.key}
          onChange={(e) =>
            onChange?.(withKey(item.copy(item.prompt, item.options, e.target.value, item.required), item))
          }
          variant="standard"
          disabled={!onChange}
        />
        <FormControlLabel
          control={
            <Switch
              checked={item.required}
              onChange={(e) =>
                onChange?.(withKey(item.copy(item.prompt, item.options, item.key, e.target.checked), item))
              }
              disabled={!onChange}
            />
          }
          label="Required"
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="subtitle2">Options</Typography>
        {item.options.asJsReadonlyArrayView().map((option, index) => (
          <Box key={keyOf(option)} sx={{ p: 1, borderRadius: 1, border: '1px solid', borderColor: 'divider' }}>
            <Stack spacing={1}>
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  label={`Option ${index + 1}`}
                  value={option.text}
                  onChange={(e) => handleOptionChange(index, option.key, e.target.value)}
                  variant="standard"
                  disabled={!onChange}
                />
                <TextField
                  label="Key"
                  value={option.key}
                  onChange={(e) => handleOptionChange(index, e.target.value, option.text)}
                  variant="standard"
                  disabled={!onChange}
                />
                {onChange && (
                  <IconButton size="small" onClick={() => removeOption(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
              <Box sx={{ bgcolor: 'grey.50', borderRadius: 1 }} padding={1}>
                <ItemList
                  items={option.items}
                  onChange={onChange && ((newItems) => handleOptionItemsChange(index, newItems))}
                />
              </Box>
            </Stack>
          </Box>
        ))}
        {onChange && (
          <IconButton size="small" onClick={addOption} sx={{ alignSelf: 'center' }}>
            <AddIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}

export default MultipleChoiceItem
