import { Alert, Button, Stack, Typography } from '@mui/material'
import { com, kotlin } from '@eidu/form'
import { useState } from 'react'
import FormPage from './FormPage'
import Page = com.eidu.sharedlib.form.definition.Page
import KtList = kotlin.collections.KtList
import { keyOf } from '../../util/withKey'
import { useSnackBar } from '../SnackBarProvider'
import encodeFormContentToYaml = com.eidu.sharedlib.form.encodeFormContentToYaml
import decodeFormContentFromYaml = com.eidu.sharedlib.form.decodeFormContentFromYaml

export type FormContentInputProps = {
  pages: KtList<Page>
  onChange?: (pages: KtList<Page>) => void
  errorMessage?: string
}

const FormContentInput = ({ pages, onChange, errorMessage }: FormContentInputProps) => {
  const { showSnackBar } = useSnackBar()

  const [expandedPageIndex, setExpandedPageIndex] = useState<number | null>(null)

  const handleInsertPage = (index: number) => {
    if (!onChange) return

    const currentPages = pages.asJsReadonlyArrayView()
    const newPage = new Page('Untitled page')
    const updatedPages = [...currentPages.slice(0, index + 1), newPage, ...currentPages.slice(index + 1)]
    onChange(KtList.fromJsArray(updatedPages))
    setExpandedPageIndex(index + 1)
  }

  const handleDeletePage = (index: number) => {
    if (!onChange) return

    const currentPages = pages.asJsReadonlyArrayView()
    const updatedPages = [...currentPages.slice(0, index), ...currentPages.slice(index + 1)]
    onChange(KtList.fromJsArray(updatedPages))

    if (index === expandedPageIndex) setExpandedPageIndex(null)
    else if (index < expandedPageIndex!) setExpandedPageIndex(expandedPageIndex! - 1)
  }

  const handlePageChange = (index: number, newPage: Page) => {
    if (!onChange) return

    const currentPages = pages.asJsReadonlyArrayView()
    const updatedPages = currentPages.map((page, i) => (i === index ? newPage : page))
    onChange(KtList.fromJsArray(updatedPages))
  }

  const handleExpand = (index: number) => {
    setExpandedPageIndex(expandedPageIndex === index ? null : index)
  }

  const handleCopyToClipboard = async () => {
    try {
      const yamlContent = encodeFormContentToYaml(pages)
      await navigator.clipboard.writeText(yamlContent)
      showSnackBar('YAML copied to clipboard', 'success')
    } catch (error) {
      showSnackBar('Failed to copy YAML to clipboard', 'error')
    }
  }

  const handlePasteFromClipboard = async () => {
    if (!onChange) return

    try {
      const clipboardText = await navigator.clipboard.readText()

      try {
        const newPages = decodeFormContentFromYaml(clipboardText)
        onChange(newPages)
        showSnackBar('YAML successfully pasted', 'success')
      } catch (parseError) {
        showSnackBar('Invalid YAML format', 'error')
      }
    } catch (error) {
      showSnackBar('Failed to access clipboard', 'error')
    }
  }

  return (
    <>
      <Stack
        sx={{ width: '100%', maxWidth: '100%', flexShrink: 1, boxSizing: 'border-box' }}
        direction="row"
        alignItems="start"
        spacing="16px"
      >
        <Stack sx={{ flex: 1, minWidth: 0, boxSizing: 'border-box', overflow: 'hidden' }}>
          <Typography variant="h6">Form content</Typography>

          {onChange && (
            <Stack direction="row" spacing={2} sx={{ my: 1 }}>
              <Button variant="outlined" size="small" onClick={handleCopyToClipboard}>
                Copy YAML
              </Button>
              <Button variant="outlined" size="small" onClick={handlePasteFromClipboard}>
                Paste YAML
              </Button>
            </Stack>
          )}

          <Stack spacing={2}>
            {onChange && (
              <Button variant="outlined" size="small" onClick={() => handleInsertPage(-1)} sx={{ alignSelf: 'center' }}>
                Insert Page
              </Button>
            )}

            {pages.asJsReadonlyArrayView().map((page, index) => (
              <FormPage
                key={keyOf(page)}
                page={page}
                index={index}
                onInsertAfter={handleInsertPage}
                onDelete={onChange && handleDeletePage}
                onChange={onChange && handlePageChange}
                isExpanded={index === expandedPageIndex}
                onExpand={() => handleExpand(index)}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    </>
  )
}

export default FormContentInput
