import { com } from '@eidu/form'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import Field = com.eidu.sharedlib.entity.field.Field
import FieldId = com.eidu.sharedlib.entity.field.FieldId

export const fieldsOfEntityType = (entityType: EntityType): readonly Field[] => [
  ...entityType.fields.asJsReadonlyArrayView(),
]

export const findFieldById = (entityType: EntityType, fieldId: FieldId): Field | undefined =>
  fieldsOfEntityType(entityType).find(({ id }) => id.equals(fieldId))

export const findEntityTypeByFieldId = (entityTypes: readonly EntityType[], fieldId: FieldId): EntityType | undefined =>
  entityTypes.find((entityType) => {
    const field = findFieldById(entityType, fieldId)
    return field !== undefined
  })

export const entityTypeIdByFieldIdOrFirst = (entityTypes: readonly EntityType[], fieldId: FieldId | undefined) =>
  fieldId ? findEntityTypeByFieldId(entityTypes, fieldId)?.id : entityTypes[0].id

export const findFieldByIdOrFirst = (
  entityType: EntityType | undefined,
  fieldId: FieldId | undefined
): Field | undefined => {
  if (entityType)
    if (fieldId) return findFieldById(entityType, fieldId)
    else return fieldsOfEntityType(entityType)[0]
  else return undefined
}

export {}
