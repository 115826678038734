import { Stack, TextField, FormControlLabel, Switch, Box, Typography, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { com, kotlin } from '@eidu/form'
import Option = com.eidu.sharedlib.form.definition.Option
import GradeDropDown = com.eidu.sharedlib.form.definition.GradeDropDown
import KtList = kotlin.collections.KtList
import { keyOf, withKey } from '../../util/withKey'

type GradeDropDownItemProps = {
  item: GradeDropDown
  onChange?: (item: GradeDropDown) => void
}

const GradeDropDownItem: React.FC<GradeDropDownItemProps> = ({ item, onChange }) => {
  const handleOptionTextChange = (index: number, text: string) => {
    const newOptions = [...item.options.asJsReadonlyArrayView()]
    newOptions[index] = withKey(new Option(text, newOptions[index].items), newOptions[index])
    onChange?.(
      withKey(
        item.copy(
          item.prompt,
          KtList.fromJsArray(newOptions),
          item.key,
          item.required,
          item.unfilledText,
          item.placeholder
        ),
        item
      )
    )
  }

  const addOption = () => {
    const newOptions = [...item.options.asJsReadonlyArrayView(), new Option('New option', KtList.fromJsArray([]))]
    onChange?.(
      item.copy(
        item.prompt,
        KtList.fromJsArray(newOptions),
        item.key,
        item.required,
        item.unfilledText,
        item.placeholder
      )
    )
  }

  const removeOption = (index: number) => {
    const newOptions = item.options.asJsReadonlyArrayView().filter((_, i) => i !== index)
    onChange?.(
      item.copy(
        item.prompt,
        KtList.fromJsArray(newOptions),
        item.key,
        item.required,
        item.unfilledText,
        item.placeholder
      )
    )
  }

  return (
    <Stack
      spacing={2}
      sx={{ p: 1, backgroundColor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}
    >
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <TextField
          fullWidth
          label="Prompt"
          value={item.prompt ?? ''}
          onChange={(e) =>
            onChange?.(
              item.copy(e.target.value, item.options, item.key, item.required, item.unfilledText, item.placeholder)
            )
          }
          variant="standard"
          disabled={!onChange}
        />
        <TextField
          label="Key"
          value={item.key}
          onChange={(e) =>
            onChange?.(
              item.copy(item.prompt, item.options, e.target.value, item.required, item.unfilledText, item.placeholder)
            )
          }
          variant="standard"
          disabled={!onChange}
        />
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          fullWidth
          label="Unfilled Text"
          value={item.unfilledText ?? ''}
          onChange={(e) =>
            onChange?.(item.copy(item.prompt, item.options, item.key, item.required, e.target.value, item.placeholder))
          }
          variant="standard"
          disabled={!onChange}
        />
        <TextField
          fullWidth
          label="Placeholder"
          value={item.placeholder ?? ''}
          onChange={(e) =>
            onChange?.(item.copy(item.prompt, item.options, item.key, item.required, item.unfilledText, e.target.value))
          }
          variant="standard"
          disabled={!onChange}
        />
        <FormControlLabel
          control={
            <Switch
              checked={item.required}
              onChange={(e) =>
                onChange?.(
                  item.copy(item.prompt, item.options, item.key, e.target.checked, item.unfilledText, item.placeholder)
                )
              }
              disabled={!onChange}
            />
          }
          label="Required"
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="subtitle2">Options</Typography>
        {item.options.asJsReadonlyArrayView().map((option, index) => (
          <Box key={keyOf(option)} sx={{ p: 1, borderRadius: 1, border: '1px solid', borderColor: 'divider' }}>
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                label={`Option ${index + 1}`}
                value={option.text}
                onChange={(e) => handleOptionTextChange(index, e.target.value)}
                variant="standard"
                disabled={!onChange}
              />
              {onChange && (
                <IconButton size="small" onClick={() => removeOption(index)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Stack>
          </Box>
        ))}
        {onChange && (
          <IconButton size="small" onClick={addOption} sx={{ alignSelf: 'center' }}>
            <AddIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}

export default GradeDropDownItem
