import { FormControlLabel, Radio, Stack, TextField } from '@mui/material'
import RadioGroup from '@mui/material/RadioGroup'
import { com } from '@eidu/form'
import { useContext } from 'react'
import EntitySelectorInput from '../entity/selector/EntitySelectorInput'
import { EntityTypesContext } from '../../io/context/EntityTypes'
import EntityChoice = com.eidu.sharedlib.form.definition.EntityChoice
import SingleChoiceStyle = com.eidu.sharedlib.form.definition.SingleChoiceStyle
import { withKey } from '../../util/withKey'

type EntityChoiceItemProps = {
  item: EntityChoice
  onChange?: (item: EntityChoice) => void
}

const EntityChoiceItem: React.FC<EntityChoiceItemProps> = ({ item, onChange }) => {
  const entityTypeRepository = useContext(EntityTypesContext)
  const entityTypes = entityTypeRepository.useSortedEntityTypes()

  return (
    <Stack spacing={2} sx={{ p: 2, backgroundColor: 'background.paper', borderRadius: 1 }}>
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <Stack spacing={2} sx={{ flex: 1 }}>
          <TextField
            label="Prompt"
            value={item.prompt}
            onChange={(e) =>
              onChange?.(withKey(item.copy(e.target.value, item.key, item.entities, item.style, item.required), item))
            }
            fullWidth
            disabled={!onChange}
          />
          <TextField
            label="Key"
            value={item.key}
            onChange={(e) =>
              onChange?.(
                withKey(item.copy(item.prompt, e.target.value, item.entities, item.style, item.required), item)
              )
            }
            fullWidth
            disabled={!onChange}
          />
          <RadioGroup
            row
            value={item.style}
            onChange={(e) =>
              onChange?.(
                withKey(
                  item.copy(
                    item.prompt,
                    item.key,
                    item.entities,
                    SingleChoiceStyle.valueOf(e.target.value),
                    item.required
                  ),
                  item
                )
              )
            }
          >
            <FormControlLabel
              value={SingleChoiceStyle.DropDown}
              control={<Radio />}
              label="Dropdown"
              disabled={!onChange}
            />
            <FormControlLabel value={SingleChoiceStyle.Radio} control={<Radio />} label="Radio" disabled={!onChange} />
          </RadioGroup>
          <EntitySelectorInput
            entityTypes={entityTypes ?? []}
            entitySelector={item.entities}
            setEntitySelector={(entities) =>
              onChange?.(withKey(item.copy(item.prompt, item.key, entities, item.style, item.required), item))
            }
            disabled={!onChange}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default EntityChoiceItem
