/* eslint-disable import/no-cycle */

import { Box, Stack, TextField } from '@mui/material'
import { com, kotlin } from '@eidu/form'
import Section = com.eidu.sharedlib.form.definition.Section
import Item = com.eidu.sharedlib.form.definition.Item
import KtList = kotlin.collections.KtList
import ItemList from './ItemList'
import { withKey } from '../../util/withKey'

type SectionItemProps = {
  item: Section
  onChange?: (item: Section) => void
}

const SectionItem: React.FC<SectionItemProps> = ({ item, onChange }) => {
  const handleItemsChange = (newItems: KtList<Item>) => {
    onChange?.(withKey(item.copy(item.title, item.key, newItems), item))
  }

  return (
    <Stack
      spacing={2}
      sx={{ p: 1, backgroundColor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}
    >
      <Stack direction="row" spacing={2}>
        <TextField
          fullWidth
          label="Title"
          value={item.title}
          onChange={(e) => onChange?.(withKey(item.copy(e.target.value, item.key, item.items), item))}
          variant="standard"
          disabled={!onChange}
        />
        <TextField
          label="Key"
          value={item.key}
          onChange={(e) => onChange?.(withKey(item.copy(item.title, e.target.value, item.items), item))}
          variant="standard"
          disabled={!onChange}
        />
      </Stack>
      <Box sx={{ bgcolor: 'grey.50', borderRadius: 1 }} padding={1}>
        <ItemList items={item.items} onChange={onChange && handleItemsChange} />
      </Box>
    </Stack>
  )
}

export default SectionItem
