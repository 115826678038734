import { com } from '@eidu/form'
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import { WarningAmber } from '@mui/icons-material'
import EntityTypeInput from './EntityTypeInput'
import TypeValidationError = com.eidu.sharedlib.entity.validation.TypeValidationError
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import FieldType = com.eidu.sharedlib.entity.field.FieldType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId

const isReference = (fieldType: FieldType) => fieldType === FieldType.Reference

export type FieldInputProps = {
  availableEntityTypes: readonly EntityType[]
  disabled: boolean
  editable: boolean
  fieldType: FieldType
  handleFieldTypeChange: (event: SelectChangeEvent) => Promise<void>
  handleReferenceTypesChange: (referenceTypes: readonly EntityTypeId[]) => Promise<void>
  handleRequiredChange: (event: SelectChangeEvent) => Promise<void>
  idPrefix: string
  name: string
  referenceTypesError: boolean
  referenceTypes: readonly EntityTypeId[]
  required: boolean
  updating: boolean
  errors: readonly TypeValidationError[] | undefined
}

const availableFieldTypeValues = FieldType.values().filter(
  (it) => it !== FieldType.Null && it !== FieldType.List && it !== FieldType.Map
)

const FieldInput = ({
  availableEntityTypes,
  disabled,
  editable,
  fieldType,
  handleFieldTypeChange,
  handleReferenceTypesChange,
  handleRequiredChange,
  idPrefix,
  name,
  referenceTypes,
  referenceTypesError,
  required,
  updating,
  errors,
}: FieldInputProps) => (
  <>
    <Stack direction="row">
      {editable ? (
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id={JSON.stringify([idPrefix, 'input', name])}>{name}</InputLabel>
          <Select
            disabled={disabled}
            name={name}
            label={name}
            labelId={JSON.stringify([idPrefix, 'input', name])}
            value={fieldType.name}
            onChange={handleFieldTypeChange}
            renderValue={(selected) =>
              updating ? <CircularProgress size="1.5em" sx={{ position: 'absolute' }} /> : selected
            }
          >
            {availableFieldTypeValues.map((option) => (
              <MenuItem key={JSON.stringify([idPrefix, 'menu', name, option.name])} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <TextField label={name} id={name} disabled value={fieldType.name} />
      )}
      <FormControlLabel
        control={<Checkbox checked={required} disabled={!editable} onChange={handleRequiredChange} />}
        label="Required"
        sx={{ paddingLeft: 2, flex: 1 }}
      />
    </Stack>
    {isReference(fieldType) && (
      <Stack direction="row" sx={{ marginTop: 3 }}>
        <Box sx={{ flex: 0.1 }} />
        <FormControl disabled={!editable} sx={{ flex: 0.9 }}>
          <InputLabel id={`${idPrefix}-reftype-${name}`}>Valid types for {name}</InputLabel>
          <EntityTypeInput
            label={`Valid types for ${name}`}
            labelId={`${idPrefix}-reftype-${name}`}
            disabled={disabled}
            availableEntityTypes={availableEntityTypes}
            selectedEntityTypes={referenceTypes}
            setSelectedEntityTypes={(selectedEntityTypes) => handleReferenceTypesChange(selectedEntityTypes)}
            error={referenceTypesError}
          />
        </FormControl>
        <FormControlLabel control={<Box />} label="" sx={{ paddingLeft: 2, flex: 1 }} />
      </Stack>
    )}
    {errors && (
      <Typography sx={{ marginTop: 1 }}>
        <WarningAmber color="warning" sx={{ margin: 0, paddingX: 1, verticalAlign: 'middle' }} />
        Validation failed: {errors.map((it) => it.message).join(', ')}
      </Typography>
    )}
  </>
)

export default FieldInput
