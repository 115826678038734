import { useParams } from 'react-router-dom'
import React, { useContext } from 'react'
import { com } from '@eidu/form'
import { EntityTypesContext } from '../../../io/context/EntityTypes'
import UploadPage from '../UploadPage'
import entityTypeIdFromString = com.eidu.sharedlib.entity.type.entityTypeIdFromString

export const ConfigurePageRoute = () => {
  const params = useParams()
  const entityTypeRepository = useContext(EntityTypesContext)

  if (params.entityTypeId)
    return (
      <UploadPage
        entityTypeId={entityTypeIdFromString(params.entityTypeId)}
        entityTypeRepository={entityTypeRepository}
        configure
      />
    )
  else return <UploadPage entityTypeId={undefined} entityTypeRepository={entityTypeRepository} configure />
}

export default ConfigurePageRoute
