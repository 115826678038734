/**
 * @deprecated The method should not be used
 */
const actions = ['View', 'Submit'] as const

/**
 * @deprecated The method should not be used
 */
export type Action = (typeof actions)[number]
export default actions
