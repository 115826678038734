import { Availability } from '../Availability'
import Program from '../../../user/Program'
import normalizedActions from './normalizedActions'
import normalizedRoles from './normalizedRoles'
import normalizedPrograms from './normalizedPrograms'

/**
 * @deprecated The method should not be used
 */
const normalizedAvailability = (
  { actions, role, program }: Availability,
  programOptions: readonly Program[],
  organizationId: string
) => ({
  actions: normalizedActions(actions),
  role: role.type === 'Any' ? role : { ...role, names: normalizedRoles(role.names, organizationId) },
  program: program.type === 'Any' ? program : { ...program, names: normalizedPrograms(program.names, programOptions) },
})

export default normalizedAvailability
