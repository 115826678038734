import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { com } from '@eidu/form'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import entityTypeIdFromString = com.eidu.sharedlib.entity.type.entityTypeIdFromString

type EntityTypeSingleSelectProps = {
  label: string
  options: readonly EntityType[]
  allOption: boolean
  entityTypeId: EntityTypeId | undefined
  setEntityTypeId: (entityTypeId: EntityTypeId | undefined) => void
  disabled?: boolean
}

export const EntityTypeSingleSelect = ({
  label,
  options,
  allOption,
  entityTypeId,
  setEntityTypeId,
  disabled,
}: EntityTypeSingleSelectProps) => (
  <FormControl>
    <InputLabel id="entity-type-label">{label}</InputLabel>
    <Select
      labelId="entity-type-label"
      value={entityTypeId ? entityTypeId.asString() : allOption ? 'All' : undefined}
      label="type"
      onChange={(event) =>
        setEntityTypeId(event.target.value === 'All' ? undefined : entityTypeIdFromString(event.target.value))
      }
      disabled={disabled}
    >
      {allOption && (
        <MenuItem key="All" value="All">
          <em>All</em>
        </MenuItem>
      )}
      {options.map(({ id, name }) => (
        <MenuItem key={id.asString()} value={id.asString()}>
          {name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default EntityTypeSingleSelect
