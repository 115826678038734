import { useContext, useEffect, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import { EditNote, List, Lock, Settings } from '@mui/icons-material'
import { Alert, AlertTitle, Button, Collapse } from '@mui/material'
import { com } from '@eidu/form'
import LoadingOverlay from '../components/LoadingOverlay'
import DrawerScaffold, { DrawerItem } from '../components/DrawerScaffold'
import SnackBarProvider from '../components/SnackBarProvider'
import { EntityTypesContext } from '../io/context/EntityTypes'
import usePermittedActions from '../io/usePermittedActions'
import GlobalAction = com.eidu.sharedlib.entity.permission.GlobalAction
import PermittedGlobalAction = com.eidu.sharedlib.entity.permission.PermittedGlobalAction

const LandingPage = () => {
  const entityTypeRepository = useContext(EntityTypesContext)

  useEffect(() => {
    entityTypeRepository.getAll().then()
  }, [])

  const requested = useRef([
    new PermittedGlobalAction(GlobalAction.ManageForms),
    new PermittedGlobalAction(GlobalAction.ManageEntityTypes),
    new PermittedGlobalAction(GlobalAction.ManagePermissionGrants),
  ])
  const [permissions, fetchPermissionsError] = usePermittedActions(requested.current)

  const entityTypes = entityTypeRepository.useSortedEntityTypes() ?? []

  const error = entityTypeRepository.useError()
  const loading = entityTypeRepository.useLoading()

  if (error) {
    return (
      <SnackBarProvider>
        <Collapse in={!!error}>
          <Alert
            variant="filled"
            severity="error"
            sx={{ margin: 2 }}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={async () => {
                  await entityTypeRepository.clearError()
                  await entityTypeRepository.refreshAll()
                }}
              >
                Refresh
              </Button>
            }
          >
            <AlertTitle>Failed to load entities. Please try again.</AlertTitle>
            <div>{String(error)}</div>
          </Alert>
        </Collapse>
      </SnackBarProvider>
    )
  }

  if (fetchPermissionsError) {
    return (
      <SnackBarProvider>
        <Collapse in={!!fetchPermissionsError}>
          <Alert
            variant="filled"
            severity="error"
            sx={{ margin: 2 }}
            action={
              <Button color="inherit" size="small" onClick={async () => window.location.reload()}>
                Refresh
              </Button>
            }
          >
            <AlertTitle>Failed to load permissions. Please reload the page.</AlertTitle>
            <div>{String(fetchPermissionsError)}</div>
          </Alert>
        </Collapse>
      </SnackBarProvider>
    )
  }

  const hasGlobalPermission = (action: GlobalAction) =>
    permissions?.some((permission) => permission.equals(new PermittedGlobalAction(action)))

  const globalFeatures: DrawerItem[][] = [
    hasGlobalPermission(GlobalAction.ManageForms) && [
      {
        icon: <EditNote />,
        text: 'Configure forms (Legacy EIDU 1.0)',
        key: 'configure-forms',
        path: `/forms`,
      },
    ],
    hasGlobalPermission(GlobalAction.ManageEntityTypes) && [
      {
        icon: <Settings />,
        text: 'Configure entity types',
        key: 'configure-entity-types',
        path: `/entity-types`,
      },
    ],
    hasGlobalPermission(GlobalAction.ManagePermissionGrants) && [
      {
        icon: <Lock />,
        text: 'Access control',
        key: 'access-control',
        path: `/access-control`,
      },
    ],
    entityTypes.map(({ id, name }) => ({
      icon: <List />,
      text: name,
      key: `type-${id.asString()}`,
      path: `/entities/${id.asString()}`,
    })),
  ].filter(Boolean) as DrawerItem[][]

  return (
    <SnackBarProvider>
      <DrawerScaffold itemGroups={globalFeatures}>
        <Outlet />
      </DrawerScaffold>
      <LoadingOverlay isOpen={loading} />
    </SnackBarProvider>
  )
}

export default LandingPage
