import { useContext, useEffect, useState } from 'react'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import { com, kotlin } from '@eidu/form'
import AuthenticationContext, { createAuthContext } from '../api/authorization/AuthenticationContext'
import { requireNotUndefinedOrNull } from '../util/require'
import getPermittedActions from '../api/entity/permission/getPermittedActions'
import PermittedActions = com.eidu.sharedlib.entity.permission.PermittedActions
import KtList = kotlin.collections.KtList
import PermittedAction = com.eidu.sharedlib.entity.permission.PermittedAction

let globalAuthContext: AuthenticationContext | undefined

export function setGlobalAuthContext(authContext: IAuthContext) {
  globalAuthContext = createAuthContext(authContext)
}

const requireAuthContext = () =>
  requireNotUndefinedOrNull(
    globalAuthContext,
    'Authentication context is missing. This may be because your user account is not linked to a user entity. Please try again or contact support for assistance if the problem persists.'
  )

const usePermittedActions = (
  requested: readonly PermittedAction[],
  deps?: readonly unknown[]
): [readonly PermittedAction[] | undefined, string | undefined, boolean] => {
  const [permissions, setPermissions] = useState<readonly PermittedAction[]>()
  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const authContext = useContext<IAuthContext>(AuthContext)

  useEffect(() => {
    setGlobalAuthContext(authContext)
  }, [authContext])
  useEffect(() => {
    const fetchPermissions = async () => {
      setLoading(true)
      const authCtx = requireAuthContext()
      const userPermissions = await getPermittedActions({
        requested: new PermittedActions(KtList.fromJsArray(requested)),
        authContext: authCtx,
      })
      setPermissions(userPermissions.permittedActions.asJsReadonlyArrayView())
    }
    fetchPermissions()
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [authContext, requested, ...(deps ?? [])])

  return [permissions, error, loading]
}

export default usePermittedActions
