/**
 * @deprecated The method should not be used
 */
export const eiduKenyaOrganizationId = '15856a8e-3785-4929-b410-8c3080ac1bb0'

/**
 * @deprecated The method should not be used
 */
export const iwOrganizationId = '49414d58-5ac0-4b0e-974d-6441ea93ab51'

/**
 * @deprecated The method should not be used
 */
export const ttwfOrganizationId = '98f92961-0722-4334-9684-b4185fce946c'

/**
 * @deprecated The method should not be used
 */
export const nigeriaKatsinaOrganizationId = '99faf518-e35f-45ec-b30c-7f43c07322d0'

/**
 * @deprecated The method should not be used
 */
export const nigeriaOyoOrganizationId = '8ca29964-c2c3-4d3d-9b41-f70ddb156b03'

/**
 * @deprecated The method should not be used
 */
export const pakistanOrganizationId = '54d38f91-0c9b-4d8d-9ec7-9bc9e06451d0'
