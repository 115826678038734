import { Stack, Switch, TextField, FormControlLabel } from '@mui/material'
import { com } from '@eidu/form'
import Date = com.eidu.sharedlib.form.definition.Date
import { withKey } from '../../util/withKey'

type DateItemProps = {
  item: Date
  onChange?: (item: Date) => void
}

const DateItem: React.FC<DateItemProps> = ({ item, onChange }) => (
  <Stack
    direction="row"
    spacing={2}
    alignItems="center"
    sx={{
      p: 1,
      bgcolor: 'background.paper',
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'divider',
    }}
  >
    <TextField
      label="Prompt"
      value={item.prompt}
      onChange={(e) =>
        onChange?.(withKey(item.copy(e.target.value, item.key, item.useCurrentAsDefault, item.required), item))
      }
      variant="standard"
      fullWidth
      disabled={!onChange}
    />
    <TextField
      label="Key"
      value={item.key}
      onChange={(e) =>
        onChange?.(withKey(item.copy(item.prompt, e.target.value, item.useCurrentAsDefault, item.required), item))
      }
      variant="standard"
      disabled={!onChange}
    />
    <FormControlLabel
      control={
        <Switch
          checked={item.useCurrentAsDefault}
          onChange={(e) => onChange?.(withKey(item.copy(item.prompt, item.key, e.target.checked, item.required), item))}
          disabled={!onChange}
        />
      }
      label="Default&nbsp;to&nbsp;current"
    />
    <FormControlLabel
      control={
        <Switch
          checked={item.required}
          onChange={(e) =>
            onChange?.(withKey(item.copy(item.prompt, item.key, item.useCurrentAsDefault, e.target.checked), item))
          }
          disabled={!onChange}
        />
      }
      label="Required"
    />
  </Stack>
)

export default DateItem
