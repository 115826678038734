import { com } from '@eidu/form'
import DraftFieldValue from './DraftFieldValue'
import { fieldValueFromString } from './FieldValueUtil'
import EntityWithLabelAndRelated from './EntityWithLabelAndRelated'
import Field = com.eidu.sharedlib.entity.field.Field
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import ReferenceValue = com.eidu.sharedlib.entity.field.ReferenceValue
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import Failure = com.eidu.sharedlib.util.Failure
import Success = com.eidu.sharedlib.util.Success

const hasFieldError = (
  field: Field,
  draftFieldValue: DraftFieldValue | undefined,
  referenceableEntitiesByTypeId: ReadonlyMap<EntityTypeId, ReadonlyMap<EntityId, EntityWithLabelAndRelated>>
) => {
  if (field.required && !draftFieldValue?.value) return true
  if (draftFieldValue === undefined) return false

  const fieldValueOutcome = fieldValueFromString(draftFieldValue.value, draftFieldValue.type)
  if (fieldValueOutcome instanceof Failure) return true

  const fieldValue = (fieldValueOutcome as Success<FieldValue>)?.value

  if (fieldValue instanceof ReferenceValue) {
    return ![...referenceableEntitiesByTypeId.entries()].some(
      ([typeId, entityById]) =>
        entityById.has(fieldValue.value) &&
        (!field.validReferenceTypes || field.validReferenceTypes.asJsReadonlySetView().has(typeId))
    )
  } else {
    return false
  }
}

export default hasFieldError
