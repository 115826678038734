import './App.css'
import { Stack, ThemeProvider, Toolbar } from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import TopAppBar from './components/TopAppBar'
import { EntityTypesProvider } from './io/context/EntityTypes'
import LandingPage from './pages/LandingPage'
import eiduTheme from './ui/theme/eiduTheme'
import { createAuthContext } from './api/authorization/AuthenticationContext'

const App = () => {
  const iAuthContext = useContext<IAuthContext>(AuthContext)
  const authContext = createAuthContext(iAuthContext)
  const { loginInProgress, tokenData, logOut } = iAuthContext

  const accessTokenExpired = tokenData?.exp === undefined || tokenData.exp * 1000 < Date.now()

  const appContent = () => {
    if (loginInProgress || accessTokenExpired) return null
    else
      return (
        <ThemeProvider theme={eiduTheme}>
          <Stack className="app">
            <TopAppBar authContext={authContext} signOut={logOut} />
            <Toolbar />
            <Stack flex={1}>
              <LandingPage />
            </Stack>
          </Stack>
        </ThemeProvider>
      )
  }

  return <EntityTypesProvider>{appContent()}</EntityTypesProvider>
}

export default App
