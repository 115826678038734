import { Decoration, DecorationSet, EditorView, RangeSetBuilder } from '@uiw/react-codemirror'
import { com } from '@eidu/form'
import NamedLabelFormat = com.eidu.sharedlib.entity.label.NamedLabelFormat

const validFieldNameStyle = {
  attributes: {
    style: 'color: royalblue',
  },
}

const invalidFieldNameStyle = {
  attributes: {
    style: 'color: red; text-decoration: underline; text-decoration-style: dotted',
  },
}

const highlightFieldReferences = (view: EditorView, validFieldNames: readonly string[]): DecorationSet => {
  const builder = new RangeSetBuilder<Decoration>()

  const format = new NamedLabelFormat(view.state.doc.toString())
  format.variablePositions.asJsReadonlyArrayView().forEach((pos) => {
    builder.add(
      pos.start,
      pos.endInclusive + 1,
      Decoration.mark(validFieldNames.includes(pos.name) ? validFieldNameStyle : invalidFieldNameStyle)
    )
  })

  return builder.finish()
}

export default highlightFieldReferences
