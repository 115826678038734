import { Stack, TextField } from '@mui/material'
import { com } from '@eidu/form'
import Description = com.eidu.sharedlib.form.definition.Description
import { withKey } from '../../util/withKey'

type DescriptionItemProps = {
  item: Description
  onChange?: (newItem: Description) => void
  onDelete?: () => void
}

const DescriptionItem: React.FC<DescriptionItemProps> = ({ item, onChange }) => (
  <Stack
    direction="row"
    spacing={2}
    alignItems="center"
    sx={{
      p: 1,
      bgcolor: 'background.paper',
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'divider',
    }}
  >
    <TextField
      fullWidth
      multiline
      value={item.text}
      onChange={(e) => onChange?.(withKey(item.copy(e.target.value), item))}
      variant="standard"
      placeholder="Enter description"
      disabled={!onChange}
    />
  </Stack>
)

export default DescriptionItem
