import { Stack, TextField } from '@mui/material'
import { com } from '@eidu/form'
import Checkbox = com.eidu.sharedlib.form.definition.Checkbox
import { withKey } from '../../util/withKey'

type CheckboxItemProps = {
  item: Checkbox
  onChange?: (newItem: Checkbox) => void
}

const CheckboxItem: React.FC<CheckboxItemProps> = ({ item, onChange }) => (
  <Stack
    direction="row"
    spacing={2}
    alignItems="center"
    sx={{
      p: 1,
      bgcolor: 'background.paper',
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'divider',
    }}
  >
    <Stack direction="row" spacing={2} sx={{ flex: 1 }}>
      <TextField
        fullWidth
        value={item.prompt}
        onChange={(e) => onChange?.(withKey(item.copy(e.target.value, item.key), item))}
        variant="standard"
        placeholder="Enter prompt"
        label="Prompt"
        disabled={!onChange}
      />
      <TextField
        value={item.key}
        onChange={(e) => onChange?.(withKey(item.copy(item.prompt, e.target.value), item))}
        variant="standard"
        placeholder="Enter key"
        label="Key"
        disabled={!onChange}
      />
    </Stack>
  </Stack>
)

export default CheckboxItem
