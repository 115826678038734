import { com } from '@eidu/form'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId

export type DeleteEntityTypeParameters = {
  id: EntityTypeId
  authContext: AuthenticationContext
}

const deleteEntityType = ({ id, authContext }: DeleteEntityTypeParameters): Promise<void> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/types/${id.asString()}`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
    method: 'DELETE',
  }).then(async (response) => {
    if (response.status !== 200)
      throw Error(`Unexpected response code ${response.status} from deleteEntityType endpoint`)
  })

export default deleteEntityType
