import { Alert, TextField } from '@mui/material'
import { com, kotlin } from '@eidu/form'
import React from 'react'
import FormAvailabilityRow from './availability/FormAvailabilityRow'
import defaultAvailability from '../../domain/form/availability/defaultAvailability'
import { Availability } from '../../domain/form/availability/Availability'
import Program from '../../domain/user/Program'
import { hasTitleError } from '../../domain/form/hasConfigurationErrors'
import FormContentInput from './FormContentInput'
import KtList = kotlin.collections.KtList
import Page = com.eidu.sharedlib.form.definition.Page

export type FormConfigurationFormProps = {
  title: string
  availabilities: readonly Availability[]
  programs: readonly Program[]
  organizationId: string
  formContent: KtList<Page> | undefined
  formContentErrorMessage: string | undefined
  onTitleChange: (title: string) => void
  onAvailabilitiesChange: (availabilities: readonly Availability[]) => void
  setFormContent: (content: KtList<Page>) => void
}

const FormConfigurationForm = ({
  title,
  availabilities,
  programs,
  organizationId,
  formContent,
  formContentErrorMessage,
  onTitleChange,
  onAvailabilitiesChange,
  setFormContent,
}: FormConfigurationFormProps) => (
  <>
    <TextField
      required
      label="Title"
      value={title}
      onChange={(event) => onTitleChange(event.target.value)}
      error={hasTitleError(title)}
    />
    {availabilities.map((availability, index) => (
      <FormAvailabilityRow
        availability={availability}
        readOnly={!index}
        idPrefix={`form-configuration-availability-${index}`}
        programs={programs}
        organizationId={organizationId}
        onAdd={() => onAvailabilitiesChange(availabilities.toSpliced(index + 1, 0, defaultAvailability))}
        onChange={(updatedAvailability) =>
          onAvailabilitiesChange(availabilities.toSpliced(index, 1, updatedAvailability))
        }
        onDelete={() => onAvailabilitiesChange(availabilities.toSpliced(index, 1))}
      />
    ))}
    <FormContentInput
      pages={formContent || KtList.fromJsArray([new Page('Untitled page')])}
      onChange={setFormContent}
      errorMessage={formContentErrorMessage}
    />
    {formContentErrorMessage && <Alert severity="error">{formContentErrorMessage}</Alert>}
  </>
)

export default FormConfigurationForm
