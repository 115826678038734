import { com, kotlin } from '@eidu/form'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import serializeToJsonString = com.eidu.sharedlib.entity.util.serializeToJsonString
import PatchEntityRequest = com.eidu.sharedlib.entity.api.entities.PatchEntityRequest
import KtMap = kotlin.collections.KtMap
import EntityId = com.eidu.sharedlib.entity.EntityId
import Patch from './Patch'

type PatchEntityParameters = {
  id: EntityId
  patch: Patch
  authContext: AuthenticationContext
}

const patchEntity = async ({ id, patch, authContext }: PatchEntityParameters) => {
  const response = await fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/entities/${id.asString()}`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
    body: serializeToJsonString(
      new PatchEntityRequest(
        patch.valuesByFieldId ? KtMap.fromJsMap(patch.valuesByFieldId) : null,
        patch.links ? KtMap.fromJsMap(patch.links) : null
      )
    ),
    method: 'PATCH',
  })
  if (response.status !== 200) throw Error(`Unexpected response code ${response.status} from patchEntity endpoint`)
}

export default patchEntity
