import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { com } from '@eidu/form'
import { Tooltip } from '@mui/material'
import { Check, Error } from '@mui/icons-material'
import React from 'react'
import { GridApiCommunity } from '@mui/x-data-grid/internals'
import FieldData from '../../domain/entity/FieldData'
import DataGridWithPageSelection from '../DataGridWithPageSelection'
import PaginationModel from '../../util/pagination/PaginationModel'
import getRenderFieldCell, { CellContent, cellContentComparator } from './getRenderFieldCell'
import { EntityValidationError3D } from '../../domain/entity/createValidatedFieldValues'
import EntityWithLabel from '../../domain/entity/EntityWithLabel'
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityType = com.eidu.sharedlib.entity.type.EntityType

const renderValidationCell = (params: GridRenderCellParams) =>
  (params.value && (
    <Tooltip title="Validation failed">
      <Error color="error" sx={{ margin: 0, paddingX: 1 }} />
    </Tooltip>
  )) || (
    <Tooltip title="Validated">
      <Check color="success" sx={{ margin: 0, paddingX: 1 }} />
    </Tooltip>
  )

const getColumns = (
  fields: readonly FieldData[],
  relatedEntities: ReadonlyMap<EntityId, EntityWithLabel | null>,
  relatedTypes: ReadonlyMap<EntityTypeId, EntityType>
): readonly GridColDef[] => [
  { field: 'id', headerName: 'Row' },
  {
    field: 'validation',
    headerName: 'Validation',
    renderCell: renderValidationCell,
  },
  ...fields.map(
    ({ field }): GridColDef => ({
      field: `field-${field.id.asString()}`,
      headerName: field.name,
      sortComparator: cellContentComparator,
      renderCell: getRenderFieldCell(field.type, relatedEntities, relatedTypes),
      display: 'flex',
    })
  ),
]

const getRows = (
  data: readonly (readonly string[])[],
  fields: readonly FieldData[],
  validationErrors: EntityValidationError3D | undefined
): [string, CellContent | number][] =>
  data.map((row, index) =>
    Object.fromEntries([
      ['id', index + 1],
      [
        'validation',
        validationErrors &&
          validationErrors[index] &&
          validationErrors[index].some((it) => it != null && it.length > 0),
      ],
      ...fields.map(({ field }, colIndex) => [
        `field-${field.id.asString()}`,
        {
          value: row[colIndex],
          error: (() =>
            validationErrors &&
            validationErrors[index] &&
            validationErrors[index][colIndex] &&
            validationErrors[index][colIndex].map((it) => it.message).join(', '))(),
        },
      ]),
    ])
  )

type EntityDataGridParams = {
  data: readonly (readonly string[])[]
  fields: readonly FieldData[]
  referencedEntities: ReadonlyMap<EntityId, EntityWithLabel | null>
  referencedTypes: ReadonlyMap<EntityTypeId, EntityType>
  paginationModel: PaginationModel
  setPaginationModel: (paginationModel: PaginationModel) => void
  validationErrors: EntityValidationError3D | undefined
  apiRef?: React.MutableRefObject<GridApiCommunity>
}

const UploadEntityDataGrid = ({
  data,
  fields,
  referencedEntities,
  referencedTypes,
  paginationModel,
  setPaginationModel,
  validationErrors,
  apiRef,
}: EntityDataGridParams) => (
  <DataGridWithPageSelection
    columns={getColumns(fields, referencedEntities, referencedTypes)}
    rows={getRows(data, fields, validationErrors)}
    paginationMode="client"
    paginationModel={paginationModel}
    onPaginationModelChange={setPaginationModel}
    apiRef={apiRef}
  />
)

export default UploadEntityDataGrid
