import { Button, Collapse, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { com } from '@eidu/form'
import ItemList from './ItemList'
import Page = com.eidu.sharedlib.form.definition.Page
import { withKey } from '../../util/withKey'

type FormPageProps = {
  page: Page
  index: number
  onInsertAfter: (index: number) => void
  onDelete?: (index: number) => void
  onChange?: (index: number, newPage: Page) => void
  isExpanded: boolean
  onExpand: () => void
}

const FormPage: React.FC<FormPageProps> = ({
  page,
  index,
  onInsertAfter,
  onDelete,
  onChange,
  isExpanded,
  onExpand,
}) => (
  <Stack spacing={1}>
    <Paper
      elevation={1}
      sx={{
        p: 2,
        borderRadius: '4px',
        bgcolor: 'grey.50',
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ flex: 1 }}>
            <IconButton
              size="small"
              onClick={onExpand}
              aria-expanded={isExpanded}
              aria-label="toggle page expansion"
              sx={{
                transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
                transition: 'transform 200ms',
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
            {onChange ? (
              <TextField
                value={page.title}
                onChange={(e) => onChange(index, withKey(page.copy(e.target.value), page))}
                variant="standard"
                fullWidth
              />
            ) : (
              <Typography variant="subtitle1">{page.title}</Typography>
            )}
          </Stack>
          {onDelete && (
            <IconButton aria-label="delete page" onClick={() => onDelete(index)} size="small">
              <DeleteIcon />
            </IconButton>
          )}
        </Stack>

        <Collapse in={isExpanded} timeout={200}>
          <ItemList
            items={page.items}
            onChange={onChange && ((newItems) => onChange(index, withKey(page.copy(page.title, newItems), page)))}
          />
        </Collapse>
      </Stack>
    </Paper>

    {onChange && (
      <Button variant="outlined" size="small" onClick={() => onInsertAfter(index)} sx={{ alignSelf: 'center' }}>
        Insert Page
      </Button>
    )}
  </Stack>
)

export default FormPage
