import { com } from '@eidu/form'
import Field = com.eidu.sharedlib.entity.field.Field

export type NewFieldData = {
  existing: false
  field: Field
  editable: true
  columnIndex: number
}

export type ExistingFieldData = {
  existing: true
  field: Field
  editable: false
  columnIndex?: number
}

type FieldData = NewFieldData | ExistingFieldData

export const fieldDataFromNewField = (field: Field, columnIndex: number): NewFieldData => ({
  existing: false,
  field,
  editable: true,
  columnIndex,
})

export const fieldDataFromExistingField = (field: Field, columnIndex: number | undefined): ExistingFieldData => ({
  existing: true,
  field,
  editable: false,
  columnIndex,
})

export default FieldData
