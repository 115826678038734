import { FormControlLabel, Stack, Switch, TextField } from '@mui/material'
import { com } from '@eidu/form'
import MultiLine = com.eidu.sharedlib.form.definition.MultiLine
import { withKey } from '../../util/withKey'

type MultiLineItemProps = {
  item: MultiLine
  onChange?: (newItem: MultiLine) => void
  onDelete?: () => void
}

const MultiLineItem: React.FC<MultiLineItemProps> = ({ item, onChange }) => (
  <Stack
    direction="row"
    spacing={2}
    alignItems="flex-start"
    sx={{
      p: 1,
      bgcolor: 'background.paper',
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'divider',
    }}
  >
    <Stack direction="row" spacing={2} sx={{ flex: 1 }}>
      <TextField
        fullWidth
        value={item.prompt}
        onChange={(e) => onChange?.(withKey(item.copy(e.target.value, item.key, item.required), item))}
        variant="standard"
        placeholder="Enter prompt"
        label="Prompt"
        disabled={!onChange}
      />
      <TextField
        value={item.key}
        onChange={(e) => onChange?.(withKey(item.copy(item.prompt, e.target.value, item.required), item))}
        variant="standard"
        placeholder="Enter key"
        label="Key"
        disabled={!onChange}
      />
      <FormControlLabel
        control={
          <Switch
            checked={item.required}
            onChange={(e) => onChange?.(withKey(item.copy(item.prompt, item.key, e.target.checked), item))}
            disabled={!onChange}
          />
        }
        label="Required"
      />
    </Stack>
  </Stack>
)

export default MultiLineItem
