import { com, kotlin, Nullable } from '@eidu/form'
import FieldData from './FieldData'
import { fieldValuesFromStringsBatch } from './FieldValueUtil'
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import validateFieldValuesBatch = com.eidu.sharedlib.entity.validation.validateFieldValuesBatch
import KtList = kotlin.collections.KtList
import KtMap = kotlin.collections.KtMap
import ReferenceValue = com.eidu.sharedlib.entity.field.ReferenceValue
import EntityValidationError = com.eidu.sharedlib.entity.validation.EntityValidationError
import Outcome = com.eidu.sharedlib.util.Outcome
import Failure = com.eidu.sharedlib.util.Failure
import Success = com.eidu.sharedlib.util.Success

export type EntityValidationError1D = Nullable<readonly EntityValidationError[]>
export type EntityValidationError2D = Nullable<readonly EntityValidationError1D[]>
export type EntityValidationError3D = readonly EntityValidationError2D[]

const hasErrors = (validationResult: EntityValidationError3D) =>
  validationResult.some((outer) => outer != null && outer.some((it) => it != null))

const createValidatedFieldValues = async (
  data: readonly (readonly string[])[],
  fields: readonly FieldData[],
  getTypeIds: (ids: EntityId[]) => Promise<ReadonlyMap<EntityId, EntityTypeId>>
): Promise<Outcome<EntityValidationError3D, readonly ReadonlyMap<EntityId, EntityTypeId>[]>> => {
  const numColumns = Math.max(...data.map((row) => row.length))
  if (fields.length !== numColumns) throw Error('Number of columns mismatch')

  const valuesOutcome = fieldValuesFromStringsBatch(data, fields)
  if (valuesOutcome instanceof Failure) {
    return valuesOutcome as Failure<EntityValidationError3D>
  }

  const fieldValues = (valuesOutcome as Success<readonly Map<FieldId, FieldValue>[]>).value
  if (!fieldValues.length) throw Error('No entities found')

  const referencedIds = fieldValues.flatMap((it) =>
    Array.from(it.values()).flatMap((value) => (value instanceof ReferenceValue ? [value.value] : []))
  )
  const typeIds = await getTypeIds(referencedIds)
  const getTypeId = async (id: EntityId) => typeIds.get(id)

  const fieldsOnly = fields.map((it) => it.field)

  const validationResults = await validateFieldValuesBatch(
    KtList.fromJsArray(fieldValues.map((it) => KtMap.fromJsMap(it))),
    KtList.fromJsArray(fieldsOnly),
    getTypeId
  )

  if (hasErrors(validationResults)) {
    return new Failure(validationResults)
  } else {
    return new Success(fieldValues)
  }
}

export default createValidatedFieldValues
