import { Alert, Button, CircularProgress, Stack, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { com } from '@eidu/form'
import SaveIcon from '@mui/icons-material/Save'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import getJobStatus from '../../api/entity/job/getJobStatus'
import JobStatusFinished = com.eidu.sharedlib.entity.job.JobStatusFinished
import JobStatusFailed = com.eidu.sharedlib.entity.job.JobStatusFailed
import JobStatusResultExpired = com.eidu.sharedlib.entity.job.JobStatusResultExpired
import JobStatusUnknown = com.eidu.sharedlib.entity.job.JobStatusUnknown
import { createAuthContext } from '../../api/authorization/AuthenticationContext'
import { requireNotUndefinedOrNull } from '../../util/require'

const Waiting = () => (
  <Stack spacing={1}>
    <Alert severity="info">
      Please wait while we prepare your data.
      <br />
      Depending on the size of the data to be exported, this may take up to fifteen minutes.
    </Alert>
    <div>
      <Button size="large" variant="contained" disabled startIcon={<CircularProgress size="1em" color="inherit" />}>
        Download
      </Button>
    </div>
  </Stack>
)

const DownloadReady = ({ resultLocation }: { resultLocation: string }) => (
  <Stack spacing={1}>
    <Alert severity="success">
      Your data is ready!
      <br />
      Click the button to download it.
    </Alert>
    <div>
      <Button
        onClick={() => {
          window.location.href = resultLocation
        }}
        size="large"
        variant="contained"
        startIcon={<SaveIcon />}
      >
        Download
      </Button>
    </div>
  </Stack>
)

export type ExportPageContentProps = {
  resultLocation: string | undefined
  error: string | undefined
}

export const ExportPageContent = ({ resultLocation, error }: ExportPageContentProps) => (
  <Stack sx={{ m: 3 }}>
    <Typography variant="h4" sx={{ mb: 1 }}>
      Data Export
    </Typography>
    {error && <Alert severity="error">{error}</Alert>}
    {!error && !resultLocation && <Waiting />}
    {!error && resultLocation && <DownloadReady resultLocation={resultLocation} />}
  </Stack>
)

export type ExportPageProps = {
  jobId: string
}

const ExportPage = ({ jobId }: ExportPageProps) => {
  const [error, setError] = useState<string | undefined>(undefined)
  const [resultLocation, setResultLocation] = useState<string | undefined>(undefined)

  const authContext = createAuthContext(useContext<IAuthContext>(AuthContext))

  const checkJobStatus = (interval: NodeJS.Timeout) => {
    getJobStatus({ jobId, authContext: requireNotUndefinedOrNull(authContext) }).then((jobStatus) => {
      if (!(jobStatus instanceof JobStatusUnknown)) {
        clearInterval(interval)
        if (jobStatus instanceof JobStatusFinished) setResultLocation(jobStatus.resultLocation)
        else if (jobStatus instanceof JobStatusFailed)
          setError('The data export failed. Please try again, or contact support if the problem persists.')
        else if (jobStatus instanceof JobStatusResultExpired)
          setError('The results from this export job are no longer available.')
        else setError('An unexpected error occurred. Please try again, or contact support if the problem persists.')
      }
    })
  }

  const monitorJobStatus = () => {
    const interval: NodeJS.Timeout = setInterval(() => checkJobStatus(interval), 10000)
    checkJobStatus(interval)
    return () => clearInterval(interval)
  }

  useEffect(monitorJobStatus, [])

  return <ExportPageContent resultLocation={resultLocation} error={error} />
}

const ExportPageRoute = () => {
  const params = new URLSearchParams(useLocation().search)
  const jobId = params.get('jobId')
  if (jobId) return <ExportPage jobId={jobId} />
  else return <Typography sx={{ padding: 3 }}>Missing parameter</Typography>
}

export default ExportPageRoute
