import { com } from '@eidu/form'
import apiBaseUrl from '../baseUrl'
import requireValidPaginationParameters from '../common/requireValidPaginationParameters'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import PaginatedResponse = com.eidu.api.PaginatedResponse
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import entityTypePaginatedResponseFromJsonString = com.eidu.sharedlib.entity.api.types.entityTypePaginatedResponseFromJsonString
import { logMessage } from '../../../util/Logging'

type GetEntityTypesParameters = { pageIndex: number; pageSize: number; authContext: AuthenticationContext }

const getEntityTypes = ({
  pageIndex,
  pageSize,
  authContext,
}: GetEntityTypesParameters): Promise<PaginatedResponse<EntityType> | null> => {
  requireValidPaginationParameters(pageIndex, pageSize)

  return fetch(
    `${apiBaseUrl}/organizations/${authContext.organizationId}/types?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      headers: { Authorization: `Bearer ${authContext.accessToken}` },
    }
  ).then(async (response) => {
    switch (response.status) {
      case 200:
        return entityTypePaginatedResponseFromJsonString(await response.text())
      case 404:
        logMessage(`Got status ${response.status} from getEntityTypes endpoint`, 'warning')
        return null
      default:
        throw Error(`Unexpected response code ${response.status} from getEntityTypes endpoint`)
    }
  })
}

export default getEntityTypes
