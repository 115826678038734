import { useContext, useEffect, useRef, useState } from 'react'
import { com, kotlin } from '@eidu/form'
import { Button, Stack, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import EntityForm from '../../components/entity/EntityForm'
import LoadingOverlay from '../../components/LoadingOverlay'
import { EntityTypesContext } from '../../io/context/EntityTypes'
import DraftFieldValue, { fieldValueToDraftFieldValue } from '../../domain/entity/DraftFieldValue'
import EqualityHashMap from '../../util/EqualityHashMap'
import { requireNotUndefinedOrNull } from '../../util/require'
import useEntityRepository from '../../io/useEntityRepository'
import useEntitiesOfType from '../../io/useEntitiesOfType'
import { createAuthContext } from '../../api/authorization/AuthenticationContext'
import { isNotUndefinedOrNull } from '../../util/predicates'
import getFormContentText from '../../api/form/getFormContentText'
import FormContentInput from '../../components/form/FormContentInput'
import usePermittedActions from '../../io/usePermittedActions'
import PermissionError from './PermissionError'
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import Entity = com.eidu.sharedlib.entity.Entity
import entityIdFromString = com.eidu.sharedlib.entity.entityIdFromString
import entityTypeIdFromString = com.eidu.sharedlib.entity.type.entityTypeIdFromString
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeKind = com.eidu.sharedlib.entity.type.EntityTypeKind
import getFormContentId = com.eidu.sharedlib.entity.getFormContentId
import PermittedEntityAction = com.eidu.sharedlib.entity.permission.PermittedEntityAction
import EntityAction = com.eidu.sharedlib.entity.permission.EntityAction
import KtList = kotlin.collections.KtList
import Page = com.eidu.sharedlib.form.definition.Page

const getNonNullFieldsFromEntity = (entity: Entity): ReadonlyMap<FieldId, DraftFieldValue> =>
  new EqualityHashMap<FieldId, DraftFieldValue>(
    Array.from(entity.valuesByFieldId.asJsReadonlyMapView())
      .filter((it): it is [FieldId, FieldValue] => it[1] !== null)
      .map(([id, value]) => [id, fieldValueToDraftFieldValue(id, value)])
  )

export type ReadyViewPageProps = {
  entityId: EntityId
  entityType: EntityType
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>
}

const ReadyViewPage = ({ entityId, entityType, entityTypes }: ReadyViewPageProps) => {
  const entityRepository = useEntityRepository(
    {
      typeId: entityType.id,
      types: entityTypes,
    },
    [entityType.id.asString(), entityTypes.size]
  )

  // Important: entityId must not change between undefined and
  // not undefined, otherwise React's hook order will be messed up.
  const entity = entityRepository.useItem(entityId)

  const navigate = useNavigate()
  const authContext = createAuthContext(useContext<IAuthContext>(AuthContext))

  const [formContentLoading, setFormContentLoading] = useState(false)

  // Important: all use* need to run, so don't replace this by anything using short-circuiting!
  const error = [entityRepository.useError(), entity === null ? 'Entity not found' : undefined]
    .find((it) => !!it)
    ?.let((it) => String(it))
  const loading = [entityRepository.useLoading(), entity === undefined].some((it) => it) || formContentLoading
  const [formContent, setFormContent] = useState<KtList<Page>>()

  const [fields, setFields] = useState<ReadonlyMap<FieldId, DraftFieldValue>>(new Map())

  const requested = useRef([
    new PermittedEntityAction(EntityAction.UpdateEntity, entityId),
    new PermittedEntityAction(EntityAction.ReadEntity, entityId),
  ])
  const [permissions] = usePermittedActions(requested.current)

  const hasReadPermission = permissions?.some(
    (permittedAction) =>
      permittedAction instanceof PermittedEntityAction &&
      permittedAction.target.equals(entityId) &&
      permittedAction.action === EntityAction.ReadEntity
  )
  const hasUpdatePermission = permissions?.some(
    (permittedAction) =>
      permittedAction instanceof PermittedEntityAction &&
      permittedAction.target.equals(entityId) &&
      permittedAction.action === EntityAction.UpdateEntity
  )

  const loadingOverlayOpen = loading || !(error || entityType)

  useEffect(() => {
    if (entity) {
      setFields(getNonNullFieldsFromEntity(entity.entity))

      const formContentId = getFormContentId(entity.entity) ?? undefined
      if (isNotUndefinedOrNull(formContentId)) {
        setFormContentLoading(true)
        getFormContentText({
          formContentId: formContentId.asString(),
          authContext: requireNotUndefinedOrNull(authContext),
        }).then((content) => {
          setFormContent(content)
          setFormContentLoading(false)
        })
      } else {
        setFormContent(undefined)
      }
    }
  }, [entity])

  const referenceableEntityTypeIds: EntityTypeId[] = entityType
    ? [
        ...new Set(
          Array.from(entityType.fields.asJsReadonlyArrayView()).flatMap((field) =>
            Array.from(field.validReferenceTypes?.asJsReadonlySetView() ?? [])
          )
        ),
      ]
    : []
  const referenceableEntitiesByTypeId = useEntitiesOfType(
    {
      typeIds: referenceableEntityTypeIds,
      allTypes: entityTypes,
    },
    []
  )

  return (
    <Stack padding={3} spacing={3}>
      {hasReadPermission && entityType && referenceableEntitiesByTypeId && (
        <>
          <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h4">{entityType.name}</Typography>
            <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'right' }}>
              {entity && hasUpdatePermission && (
                <Button onClick={() => navigate(`/entities/${entityType.id.asString()}/edit/${entityId.asString()}`)}>
                  Edit
                </Button>
              )}
            </Stack>
          </Stack>
          <EntityForm fieldsById={fields} entityType={entityType} entityTypes={entityTypes} />
          {entityType.kind === EntityTypeKind.Form && (
            <FormContentInput pages={formContent ?? KtList.fromJsArray([])} />
          )}
          <Stack direction="row" spacing={3} sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button onClick={() => navigate(-1)}>Back</Button>
          </Stack>
        </>
      )}
      {!hasReadPermission && !loadingOverlayOpen && (
        <PermissionError missingPermittedActions={[EntityAction.ReadEntity]} />
      )}
      <LoadingOverlay isOpen={loadingOverlayOpen} />
    </Stack>
  )
}

const ViewEntityPage = () => {
  const params = useParams()
  const entityId = entityIdFromString(requireNotUndefinedOrNull(params.entityId, 'Entity ID is required'))
  const entityTypeId = entityTypeIdFromString(requireNotUndefinedOrNull(params.entityTypeId))

  const entityTypeRepository = useContext(EntityTypesContext)
  const entityTypes = entityTypeRepository.useAll()
  const entityType = entityTypeRepository.useItem(entityTypeId)
  const error = entityTypeRepository.useError()?.let((it) => String(it))

  return (
    <>
      {entityType && entityTypes && (
        <ReadyViewPage entityId={entityId} entityType={entityType} entityTypes={entityTypes} />
      )}
      <LoadingOverlay isOpen={!(error || entityType)} />
    </>
  )
}

export default ViewEntityPage
