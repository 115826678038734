import { com, kotlin } from '@eidu/form'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import EntityToCreate = com.eidu.sharedlib.entity.api.entities.EntityToCreate
import PostEntitiesRequest = com.eidu.sharedlib.entity.api.entities.PostEntitiesRequest
import serializeToJsonString = com.eidu.sharedlib.entity.util.serializeToJsonString
import KtList = kotlin.collections.KtList
import PostEntitiesResponse = com.eidu.sharedlib.entity.api.entities.PostEntitiesResponse

type PostEntitiesParameters = { entities: readonly EntityToCreate[]; authContext: AuthenticationContext }

const postEntities = async ({ entities, authContext }: PostEntitiesParameters) => {
  const response = await fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/entities`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
    body: serializeToJsonString(new PostEntitiesRequest(KtList.fromJsArray(entities))),
    method: 'POST',
  })
  if (response.status !== 200) throw Error(`Unexpected response code ${response.status} from postEntities endpoint`)

  return PostEntitiesResponse.fromJsonString(await response.text())
}

export default postEntities
