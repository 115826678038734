import { com } from '@eidu/form'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import { logMessage } from '../../../util/Logging'

type GetEntityTypeParameters = { id: EntityTypeId; authContext: AuthenticationContext }

const getEntityType = ({ id, authContext }: GetEntityTypeParameters): Promise<EntityType | null> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/types/${id.asString()}`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
  }).then(async (response) => {
    switch (response.status) {
      case 200:
        return EntityType.fromJsonString(await response.text())
      case 404:
        logMessage(`Got status ${response.status} from getEntitiesBatch endpoint`, 'warning')
        return null
      default:
        throw Error(`Unexpected response code ${response.status} from getEntityType endpoint`)
    }
  })

export default getEntityType
