import { com } from '@eidu/form'
import Field = com.eidu.sharedlib.entity.field.Field
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityWithLabelAndRelated from '../domain/entity/EntityWithLabelAndRelated'
import EqualityHashMap from './EqualityHashMap'
import FieldType = com.eidu.sharedlib.entity.field.FieldType
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import ReferenceValue = com.eidu.sharedlib.entity.field.ReferenceValue
import { fieldValueToString } from '../domain/entity/DraftFieldValue'

const fieldValueLabel = (
  field: Field,
  fieldValue: FieldValue,
  entitiesByTypeId: ReadonlyMap<EntityTypeId, ReadonlyMap<EntityId, EntityWithLabelAndRelated>>
): string | undefined => {
  const referencableEntities = new EqualityHashMap<EntityId, EntityWithLabelAndRelated>(
    Array.from(field.validReferenceTypes?.asJsReadonlySetView() ?? []).flatMap((typeId) =>
      Array.from(entitiesByTypeId.get(typeId)?.entries() ?? [])
    )
  )
  return field.type === FieldType.Reference
    ? referencableEntities.get((fieldValue as ReferenceValue).value)?.primaryLabelText
    : fieldValueToString(fieldValue)
}

export default fieldValueLabel
