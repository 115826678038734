import { useContext, useEffect, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  Alert,
  Button,
  Collapse,
  Fab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { Add, EditNote } from '@mui/icons-material'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import { com } from '@eidu/form'
import LoadingOverlay from '../../components/LoadingOverlay'
import maxPageSize from '../../api/form/maxPageSize'
import fetchAllPages from '../../api/fetchAllPages'
import FormConfiguration from '../../domain/form/FormConfiguration'
import getFormConfigurations from '../../api/form/getFormConfigurations'
import sortBy from '../../util/sort/sortBy'
import RefetchableDataProvider from '../../components/RefetchableData'
import ListItemButtonWithNavigation from '../../components/ListItemButtonWithNavigation'
import { createAuthContext } from '../../api/authorization/AuthenticationContext'
import { requireNotUndefinedOrNull } from '../../util/require'
import usePermittedActions from '../../io/usePermittedActions'
import PermissionError from '../entity/PermissionError'
import PermittedGlobalAction = com.eidu.sharedlib.entity.permission.PermittedGlobalAction
import GlobalAction = com.eidu.sharedlib.entity.permission.GlobalAction

const FormConfigurationsPage = () => {
  const navigate = useNavigate()

  const location = useLocation()

  const [forms, setForms] = useState<readonly FormConfiguration[]>()
  const [error, setError] = useState<true>()

  const authContext = createAuthContext(useContext<IAuthContext>(AuthContext))

  const requested = useRef([new PermittedGlobalAction(GlobalAction.ManageForms)])
  const [permissions] = usePermittedActions(requested.current)

  const hasManageFormsPermission = permissions?.some(
    (permittedAction) => permittedAction.action === GlobalAction.ManageForms
  )

  const fetchForms = () => {
    setError(undefined)

    fetchAllPages<FormConfiguration>((index) =>
      getFormConfigurations({
        pageIndex: index,
        pageSize: maxPageSize,
        authContext: requireNotUndefinedOrNull(authContext),
      })
    ).then(
      (it) => {
        const formConfigurations = [...it]
        setForms(sortBy(formConfigurations, ({ title }) => title))
      },
      () => setError(true)
    )
  }

  useEffect(() => {
    fetchForms()
  }, [])

  const loadingOverlayOpen = !forms && !error

  return (
    <RefetchableDataProvider refetch={() => fetchForms()}>
      <Outlet />
      {location.pathname === '/forms' && hasManageFormsPermission && (
        <>
          <Fab
            color="primary"
            aria-label="Create form"
            onClick={() => navigate('new')}
            sx={{
              position: 'fixed',
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2),
            }}
          >
            <Add />
          </Fab>
          <Stack paddingX={3} paddingY={2}>
            <Collapse in={error}>
              <Alert
                variant="filled"
                severity="error"
                sx={{ margin: 2 }}
                action={
                  <Button color="inherit" size="small" onClick={() => fetchForms()}>
                    Refresh
                  </Button>
                }
              >
                Failed to load forms. Please try again.
              </Alert>
            </Collapse>
            {forms && (
              <>
                <Typography variant="h4">Available forms</Typography>
                <List>
                  {forms.map(({ title, id }) => (
                    <ListItem key={`form-configuration-${id}`}>
                      <ListItemButtonWithNavigation path={`/forms/${id}`}>
                        <ListItemIcon>
                          <EditNote />
                        </ListItemIcon>
                        <ListItemText primary={title} />
                      </ListItemButtonWithNavigation>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Stack>
        </>
      )}
      <LoadingOverlay isOpen={loadingOverlayOpen} />
      {!hasManageFormsPermission && !loadingOverlayOpen && (
        <PermissionError missingPermittedActions={[GlobalAction.ManageForms]} />
      )}
    </RefetchableDataProvider>
  )
}

export default FormConfigurationsPage
