import { com, kotlin } from '@eidu/form'
import apiBaseUrl from './baseUrl'
import bearerTokenHeader from '../authorization/bearerTokenHeader'
import AuthenticationContext from '../authorization/AuthenticationContext'
import Page = com.eidu.sharedlib.form.definition.Page
import KtList = kotlin.collections.KtList
import decodeFormContentFromJson = com.eidu.sharedlib.form.decodeFormContentFromJson

type GetFormContentTextParameters = {
  formContentId: string
  authContext: AuthenticationContext
}

const getFormContentText = ({ formContentId, authContext }: GetFormContentTextParameters): Promise<KtList<Page>> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/form-contents-text/${formContentId}`, {
    headers: bearerTokenHeader(authContext.accessToken),
  }).then(async (it) => {
    const response = await it.text()
    if (it.status === 200) return decodeFormContentFromJson(response)
    else throw Error(`Unexpected response code ${it.status} from getFormConfiguration endpoint`)
  })

export default getFormContentText
