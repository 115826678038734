import { Stack, TextField, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Switch } from '@mui/material'
import { com } from '@eidu/form'
import SingleLine = com.eidu.sharedlib.form.definition.SingleLine
import InputType = com.eidu.sharedlib.form.definition.InputType
import { withKey } from '../../util/withKey'

type SingleLineItemProps = {
  item: SingleLine
  onChange?: (newItem: SingleLine) => void
  onDelete?: () => void
}

const SingleLineItem: React.FC<SingleLineItemProps> = ({ item, onChange }) => (
  <Stack
    direction="row"
    spacing={2}
    alignItems="flex-start"
    sx={{
      p: 1,
      bgcolor: 'background.paper',
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'divider',
    }}
  >
    <Stack direction="row" spacing={2} sx={{ flex: 1 }}>
      <TextField
        fullWidth
        value={item.prompt}
        onChange={(e) => onChange?.(withKey(item.copy(e.target.value, item.key, item.inputType, item.required), item))}
        variant="standard"
        placeholder="Enter prompt"
        label="Prompt"
        disabled={!onChange}
      />
      <TextField
        value={item.key}
        onChange={(e) =>
          onChange?.(withKey(item.copy(item.prompt, e.target.value, item.inputType, item.required), item))
        }
        variant="standard"
        placeholder="Enter key"
        label="Key"
        disabled={!onChange}
      />
      <FormControl variant="standard" sx={{ minWidth: 120 }}>
        <InputLabel>Input Type</InputLabel>
        <Select
          value={item.inputType}
          onChange={(e) =>
            onChange?.(withKey(item.copy(item.prompt, item.key, e.target.value as InputType, item.required), item))
          }
          label="Input Type"
          disabled={!onChange}
        >
          {InputType.values().map((type) => (
            <MenuItem key={type.name} value={type.name}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Switch
            checked={item.required}
            onChange={(e) =>
              onChange?.(withKey(item.copy(item.prompt, item.key, item.inputType, e.target.checked), item))
            }
            disabled={!onChange}
          />
        }
        label="Required"
      />
    </Stack>
  </Stack>
)

export default SingleLineItem
