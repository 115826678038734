import React from 'react'
import { IconButton, MenuItem, Select, Stack, Typography } from '@mui/material'

export type GroupHeaderProps = {
  operator: 'AND' | 'OR'
  onOperatorChange: (newOperator: 'AND' | 'OR') => void
  onToggleNegation: () => void
  isNegated: boolean
}

const GroupHeader: React.FC<GroupHeaderProps> = ({ operator, onOperatorChange, onToggleNegation, isNegated }) => (
  <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
    <Select value={operator} onChange={(e) => onOperatorChange(e.target.value as 'AND' | 'OR')} size="small">
      <MenuItem value="AND">AND</MenuItem>
      <MenuItem value="OR">OR</MenuItem>
    </Select>
    <IconButton onClick={onToggleNegation} size="small">
      <Typography variant="caption">{isNegated ? 'Un-Negate' : 'Negate'}</Typography>
    </IconButton>
  </Stack>
)

export default GroupHeader
