import { com } from '@eidu/form'
import EntityId = com.eidu.sharedlib.entity.EntityId
import PermissionGrant = com.eidu.sharedlib.entity.permission.PermissionGrant
import PermissionGrantId = com.eidu.sharedlib.entity.permission.PermissionGrantId
import entityIdFromString = com.eidu.sharedlib.entity.entityIdFromString
import permissionGrantIdFromString = com.eidu.sharedlib.entity.permission.permissionGrantIdFromString

export type PermissionGrantDTO = {
  grantId: PermissionGrantId
  organizationId: string
  permissionGrant: PermissionGrant
  grantedBy: EntityId
}

const permissionGrantDTO: ({
  grantId,
  organizationId,
  permissionGrant,
  grantedBy,
}: {
  grantId: string
  organizationId: string
  permissionGrant: unknown
  grantedBy: string
}) => PermissionGrantDTO = ({ grantId, organizationId, permissionGrant, grantedBy }) => ({
  grantId: permissionGrantIdFromString(grantId),
  organizationId,
  permissionGrant: PermissionGrant.fromJsonString(JSON.stringify(permissionGrant)),
  grantedBy: entityIdFromString(grantedBy),
})

export default permissionGrantDTO
