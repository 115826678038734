import { com } from '@eidu/form'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import permissionGrantDTO, { PermissionGrantDTO } from '../../../domain/permission/PermissionGrantDTO'
import PermissionGrantId = com.eidu.sharedlib.entity.permission.PermissionGrantId
import { logMessage } from '../../../util/Logging'

export type GetPermissionGrantParameters = { id: PermissionGrantId; authContext: AuthenticationContext }

const getPermissionGrant = ({ id, authContext }: GetPermissionGrantParameters): Promise<PermissionGrantDTO | null> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/permissions/grants/${id.asString()}`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
  }).then(async (response) => {
    switch (response.status) {
      case 200:
        return permissionGrantDTO((await response.json()).document)
      case 404:
        logMessage(`Got status ${response.status} from getEntitiesBatch endpoint`, 'warning')
        return null
      default:
        throw Error(`Unexpected response code ${response.status} from getPermissionGrant endpoint`)
    }
  })

export default getPermissionGrant
