import { v4 as uuid } from 'uuid'

export function withKey<T>(item: T, original?: T): T & { _key: string } {
  // @ts-ignore
  if (!('_key' in item)) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    item._key = original?._key || (uuid() as string)
  }
  // @ts-ignore
  return item
}

export function keyOf<T>(item: T): string {
  // eslint-disable-next-line no-underscore-dangle
  return withKey(item)._key
}
