import { com, kotlin } from '@eidu/form'
import apiBaseUrl from '../baseUrl'
import requireValidPaginationParameters from '../common/requireValidPaginationParameters'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import Entity = com.eidu.sharedlib.entity.Entity
import entityPaginatedResponseWithRelatedFromJsonString = com.eidu.sharedlib.entity.api.entities.entityPaginatedResponseWithRelatedFromJsonString
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import PaginatedResponseWithRelated = com.eidu.sharedlib.entity.api.common.PaginatedResponseWithRelated
import KtList = kotlin.collections.KtList

type GetEntitiesOfTypeParameters = {
  id: EntityTypeId
  withRelatedEntities?: boolean
  pageIndex: number
  pageSize: number
  authContext: AuthenticationContext
}

const getEntitiesOfType = ({
  id,
  withRelatedEntities,
  pageIndex,
  pageSize,
  authContext,
}: GetEntitiesOfTypeParameters): Promise<PaginatedResponseWithRelated<Entity>> => {
  requireValidPaginationParameters(pageIndex, pageSize)

  const related = withRelatedEntities ? 'true' : 'false'

  return fetch(
    `${apiBaseUrl}/organizations/${authContext.organizationId}/types/${id.asString()}/entities?related=${related}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      headers: { Authorization: `Bearer ${authContext.accessToken}` },
    }
  ).then(async (response) => {
    switch (response.status) {
      case 200:
        return entityPaginatedResponseWithRelatedFromJsonString(await response.text())
      case 401:
      case 403:
        return new PaginatedResponseWithRelated(0, KtList.fromJsArray([]))
      default:
        throw Error(`Unexpected response code ${response.status} from getEntitiesOfType endpoint`)
    }
  })
}

export default getEntitiesOfType
