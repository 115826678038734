import { com, kotlin } from '@eidu/form'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import Field = com.eidu.sharedlib.entity.field.Field
import EntityLabel = com.eidu.sharedlib.entity.label.EntityLabel
import PatchTypeResponse = com.eidu.sharedlib.entity.api.types.PatchTypeResponse
import serializeToJsonString = com.eidu.sharedlib.entity.util.serializeToJsonString
import PatchTypeRequest = com.eidu.sharedlib.entity.api.types.PatchTypeRequest
import KtList = kotlin.collections.KtList
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId

export type PatchEntityTypeParameters = {
  id: EntityTypeId
  fieldsToCreate: readonly Field[]
  label?: EntityLabel
  authContext: AuthenticationContext
}

const patchEntityType = ({
  id,
  fieldsToCreate,
  label,
  authContext,
}: PatchEntityTypeParameters): Promise<PatchTypeResponse> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/types/${id.asString()}`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
    body: serializeToJsonString(
      new PatchTypeRequest(undefined, KtList.fromJsArray(fieldsToCreate), undefined, undefined, label)
    ),
    method: 'PATCH',
  }).then(async (response) => {
    if (response.status !== 200)
      throw Error(`Unexpected response code ${response.status} from patchEntityType endpoint`)

    return PatchTypeResponse.fromJsonString(await response.text())
  })

export default patchEntityType
