import { com, kotlin } from '@eidu/form'
import DraftFieldValue, { draftFieldValuesToFieldValues } from '../../domain/entity/DraftFieldValue'
import Link from '../../domain/entity/Link'
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityToCreate = com.eidu.sharedlib.entity.api.entities.EntityToCreate
import KtMap = kotlin.collections.KtMap

const createEntityToCreate = (typeId: EntityTypeId, fieldValues: readonly DraftFieldValue[], links: readonly Link[]) =>
  new EntityToCreate(
    typeId,
    KtMap.fromJsMap(draftFieldValuesToFieldValues(fieldValues)),
    KtMap.fromJsMap(new Map(links.map((link) => [link.key, link.value])))
  )

export default createEntityToCreate
