import { com, kotlin } from '@eidu/form'
import apiBaseUrl from './baseUrl'
import bearerTokenHeader from '../authorization/bearerTokenHeader'
import AuthenticationContext from '../authorization/AuthenticationContext'
import Page = com.eidu.sharedlib.form.definition.Page
import KtList = kotlin.collections.KtList
import encodeFormContentToJson = com.eidu.sharedlib.form.encodeFormContentToJson

type PostFormContentTextParameters = {
  pages: KtList<Page>
  authContext: AuthenticationContext
}

const postFormContentText = async ({ pages, authContext }: PostFormContentTextParameters): Promise<string> => {
  const response = await fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/form-contents-text`, {
    method: 'POST',
    headers: bearerTokenHeader(authContext.accessToken),
    body: encodeFormContentToJson(pages),
  })
  const responseJson = await response.json()
  if (response.status !== 200) throw Error(responseJson.error.message)

  return responseJson.contentId
}

export default postFormContentText
