import { Box, Card, CardContent, Typography } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { com } from '@eidu/form'
import Action = com.eidu.sharedlib.entity.permission.Action
import actionLabel from '../../domain/permission/actionLabel'
import GlobalAction = com.eidu.sharedlib.entity.permission.GlobalAction
import EntityAction = com.eidu.sharedlib.entity.permission.EntityAction

const actionKey = (action: Action) => {
  if (action instanceof GlobalAction) return action.name
  else if (action instanceof EntityAction) return action.name
  else throw new Error(`Unsupported action: ${action}`)
}

export type PermissionErrorProps = {
  missingPermittedActions: Action[]
}

const PermissionError = ({ missingPermittedActions }: PermissionErrorProps) => (
  <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" sx={{ backgroundColor: '#f5f5f5' }}>
    <Card
      variant="outlined"
      sx={{
        maxWidth: 400,
        textAlign: 'center',
        padding: 2,
        boxShadow: 3,
      }}
    >
      <CardContent>
        <ErrorOutlineIcon color="error" sx={{ fontSize: 40 }} />
        <Typography variant="h6" sx={{ mt: 1, fontWeight: 'bold' }}>
          Permission Error
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          You are missing the following permission to view this page:
        </Typography>
        {missingPermittedActions.map((action) => (
          <Typography key={actionKey(action)} variant="subtitle1" sx={{ mt: 1, fontWeight: 'bold' }}>
            {actionLabel(action)}
          </Typography>
        ))}
      </CardContent>
    </Card>
  </Box>
)

export default PermissionError
