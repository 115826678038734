/* eslint-disable import/no-cycle */

import { Stack, TextField, FormControlLabel, Switch, Box } from '@mui/material'
import { com, kotlin } from '@eidu/form'
import MultiAnswer = com.eidu.sharedlib.form.definition.MultiAnswer
import Item = com.eidu.sharedlib.form.definition.Item
import KtList = kotlin.collections.KtList
import ItemList from './ItemList'
import { withKey } from '../../util/withKey'

type MultiAnswerItemProps = {
  item: MultiAnswer
  onChange?: (item: MultiAnswer) => void
}

const MultiAnswerItem: React.FC<MultiAnswerItemProps> = ({ item, onChange }) => {
  const handleItemsChange = (newItems: KtList<Item>) => {
    onChange?.(
      withKey(item.copy(item.prompt, item.key, newItems, item.required, item.addLabel, item.removeLabel), item)
    )
  }

  return (
    <Stack
      spacing={2}
      sx={{ p: 1, backgroundColor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}
    >
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <TextField
          fullWidth
          label="Prompt"
          value={item.prompt}
          onChange={(e) =>
            onChange?.(
              withKey(
                item.copy(e.target.value, item.key, item.items, item.required, item.addLabel, item.removeLabel),
                item
              )
            )
          }
          variant="standard"
          disabled={!onChange}
        />
        <TextField
          label="Key"
          value={item.key}
          onChange={(e) =>
            onChange?.(
              withKey(
                item.copy(item.prompt, e.target.value, item.items, item.required, item.addLabel, item.removeLabel),
                item
              )
            )
          }
          variant="standard"
          disabled={!onChange}
        />
      </Stack>

      <Stack direction="row" spacing={2} alignItems="flex-start">
        <TextField
          fullWidth
          label="Add Label"
          value={item.addLabel}
          onChange={(e) =>
            onChange?.(
              withKey(
                item.copy(item.prompt, item.key, item.items, item.required, e.target.value, item.removeLabel),
                item
              )
            )
          }
          variant="standard"
          disabled={!onChange}
        />
        <TextField
          fullWidth
          label="Remove Label"
          value={item.removeLabel}
          onChange={(e) =>
            onChange?.(
              withKey(item.copy(item.prompt, item.key, item.items, item.required, item.addLabel, e.target.value), item)
            )
          }
          variant="standard"
          disabled={!onChange}
        />
        <FormControlLabel
          control={
            <Switch
              checked={item.required}
              onChange={(e) =>
                onChange?.(
                  withKey(
                    item.copy(item.prompt, item.key, item.items, e.target.checked, item.addLabel, item.removeLabel),
                    item
                  )
                )
              }
              disabled={!onChange}
            />
          }
          label="Required"
        />
      </Stack>

      <Box sx={{ bgcolor: 'grey.50', borderRadius: 1 }} padding={1}>
        <ItemList items={item.items} onChange={onChange && handleItemsChange} />
      </Box>
    </Stack>
  )
}

export default MultiAnswerItem
