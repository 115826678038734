import React, { useState } from 'react'
import { Box, Divider, IconButton, Paper, Stack, Typography } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { com } from '@eidu/form'
import GroupHeader from './GroupHeader'
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import NonEmptyList = com.quickbirdstudios.nonEmptyCollection.list.NonEmptyList
import EntitySelectorInput from './EntitySelectorInput'

type KeyedSelector = {
  id: string
  selector: EntitySelector
}

type SelectorEditorProps = {
  selector: EntitySelector
  onChange: (newSelector: EntitySelector) => void
  entityTypes: readonly EntityType[]
}

const SelectorEditor: React.FC<SelectorEditorProps> = ({ selector, onChange, entityTypes }) => {
  if (selector instanceof EntitySelector.Not) {
    return (
      <Paper
        variant="outlined"
        sx={{
          borderColor: 'blue',
          borderStyle: 'dotted',
          borderRadius: 1,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="caption" color="blue">
            NOT
          </Typography>
          <IconButton onClick={() => onChange(selector.selector)}>
            <Delete color="error" />
          </IconButton>
        </Stack>
        <SelectorEditor
          selector={selector.selector}
          onChange={(newSel) => onChange(new EntitySelector.Not(newSel))}
          entityTypes={entityTypes}
        />
      </Paper>
    )
  } else if (selector instanceof EntitySelector.And || selector instanceof EntitySelector.Or) {
    const operator = selector instanceof EntitySelector.And ? 'AND' : 'OR'
    const initialKeyed: KeyedSelector[] = selector.selectors
      .asJsReadonlyArrayView()
      .map((sel: EntitySelector) => ({ id: crypto.randomUUID(), selector: sel }))
    const [keyedChildren, setKeyedChildren] = useState<KeyedSelector[]>(initialKeyed)

    const updateChildren = (newKeyed: KeyedSelector[]) => {
      setKeyedChildren(newKeyed)
      const newChildren = NonEmptyList.fromArray(newKeyed.map((item) => item.selector))
      const newGroup = operator === 'AND' ? new EntitySelector.And(newChildren) : new EntitySelector.Or(newChildren)
      onChange(newGroup)
    }

    return (
      <Paper variant="outlined" sx={{ borderColor: 'grey', borderRadius: 1 }}>
        <GroupHeader
          operator={operator}
          isNegated={false}
          onOperatorChange={(newOp) => {
            const newGroup =
              newOp === 'AND' ? new EntitySelector.And(selector.selectors) : new EntitySelector.Or(selector.selectors)
            onChange(newGroup)
          }}
          onToggleNegation={() => onChange(new EntitySelector.Not(selector))}
        />
        <Stack>
          {keyedChildren.map((child) => (
            <Stack key={child.id} direction="row" alignItems="center">
              <SelectorEditor
                selector={child.selector}
                onChange={(newSel) => {
                  const newKeyed = keyedChildren.map((item) =>
                    item.id === child.id ? { ...item, selector: newSel } : item
                  )
                  updateChildren(newKeyed)
                }}
                entityTypes={entityTypes}
              />
              <IconButton
                onClick={() => {
                  const newKeyed = keyedChildren.filter((item) => item.id !== child.id)
                  updateChildren(newKeyed)
                }}
              >
                <Delete color="error" />
              </IconButton>
            </Stack>
          ))}
          <Box sx={{ justifyContent: 'center' }}>
            <IconButton
              onClick={() => {
                const newChild: KeyedSelector = {
                  id: crypto.randomUUID(),
                  selector: EntitySelector.All,
                }
                updateChildren([...keyedChildren, newChild])
              }}
              sx={{ width: 'auto', flexGrow: 0 }}
            >
              <Add color="primary" />
              <Typography variant="caption">Add Condition</Typography>
            </IconButton>
          </Box>
        </Stack>
      </Paper>
    )
  } else {
    return (
      <Paper variant="outlined">
        <Stack direction="row" alignItems="center" spacing={1}>
          <EntitySelectorInput entityTypes={entityTypes} entitySelector={selector} setEntitySelector={onChange} />
          <Divider />
          <Stack direction="column" alignItems="center" spacing={1} justifyContent="center">
            <IconButton onClick={() => onChange(new EntitySelector.Not(selector))}>
              <Typography variant="caption">NOT</Typography>
            </IconButton>
            <IconButton onClick={() => onChange(new EntitySelector.And(NonEmptyList.fromArray([selector])))}>
              <Typography variant="caption">Group AND</Typography>
            </IconButton>
            <IconButton onClick={() => onChange(new EntitySelector.Or(NonEmptyList.fromArray([selector])))}>
              <Typography variant="caption">Group OR</Typography>
            </IconButton>
          </Stack>
        </Stack>
      </Paper>
    )
  }
}

export default SelectorEditor
