import { com } from '@eidu/form'
import FieldData, { ExistingFieldData, fieldDataFromNewField, NewFieldData } from './FieldData'
import Field = com.eidu.sharedlib.entity.field.Field
import fieldIdFromString = com.eidu.sharedlib.entity.field.fieldIdFromString
import FieldType = com.eidu.sharedlib.entity.field.FieldType

export type FieldsAndData = { fieldData: readonly FieldData[]; data: readonly (readonly string[])[] }

const extractFieldsAndData = (
  fields: readonly ExistingFieldData[],
  allRows: readonly (readonly string[])[]
): FieldsAndData => {
  const fieldNames = allRows[0]
  const data = allRows.slice(1)
  const existingFields = fields.map((field) => {
    const index = fieldNames.indexOf(field.field.name)
    return { ...field, columnIndex: index >= 0 ? index : undefined }
  })
  const newFields: readonly NewFieldData[] = fieldNames.flatMap((fieldName, index) =>
    fields.some(({ field }) => field.name === fieldName)
      ? []
      : [
          fieldDataFromNewField(
            Field.createSimple(fieldIdFromString(crypto.randomUUID()), fieldName, FieldType.Text, true, null),
            index
          ),
        ]
  )
  const combinedFields = [...existingFields, ...newFields]
  const paddedData = data.map((value: readonly string[]) =>
    combinedFields.map(({ columnIndex }) => (columnIndex !== undefined ? value[columnIndex] : ''))
  )
  return { fieldData: combinedFields, data: paddedData }
}

export default extractFieldsAndData
