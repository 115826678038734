import { Stack, TextField, FormControlLabel, Switch } from '@mui/material'
import { com } from '@eidu/form'
import StreamDropDown = com.eidu.sharedlib.form.definition.StreamDropDown
import { withKey } from '../../util/withKey'

type StreamDropDownItemProps = {
  item: StreamDropDown
  onChange?: (item: StreamDropDown) => void
}

const StreamDropDownItem: React.FC<StreamDropDownItemProps> = ({ item, onChange }) => (
  <Stack
    spacing={2}
    sx={{ p: 1, backgroundColor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}
  >
    <Stack direction="row" spacing={2} alignItems="flex-start">
      <TextField
        fullWidth
        label="Prompt"
        value={item.prompt}
        onChange={(e) =>
          onChange?.(
            withKey(item.copy(e.target.value, item.key, item.required, item.unfilledText, item.placeholder), item)
          )
        }
        variant="standard"
        disabled={!onChange}
      />
      <TextField
        label="Key"
        value={item.key}
        onChange={(e) =>
          onChange?.(
            withKey(item.copy(item.prompt, e.target.value, item.required, item.unfilledText, item.placeholder), item)
          )
        }
        variant="standard"
        disabled={!onChange}
      />
    </Stack>

    <Stack direction="row" spacing={2} alignItems="center">
      <TextField
        fullWidth
        label="Unfilled Text"
        value={item.unfilledText ?? ''}
        onChange={(e) =>
          onChange?.(withKey(item.copy(item.prompt, item.key, item.required, e.target.value, item.placeholder), item))
        }
        variant="standard"
        disabled={!onChange}
      />
      <TextField
        fullWidth
        label="Placeholder"
        value={item.placeholder ?? ''}
        onChange={(e) =>
          onChange?.(withKey(item.copy(item.prompt, item.key, item.required, item.unfilledText, e.target.value), item))
        }
        variant="standard"
        disabled={!onChange}
      />
      <FormControlLabel
        control={
          <Switch
            checked={item.required}
            onChange={(e) =>
              onChange?.(
                withKey(item.copy(item.prompt, item.key, e.target.checked, item.unfilledText, item.placeholder), item)
              )
            }
            disabled={!onChange}
          />
        }
        label="Required"
      />
    </Stack>
  </Stack>
)

export default StreamDropDownItem
