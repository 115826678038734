import React, { useEffect, useState } from 'react'
import { Box, IconButton, Stack } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { com } from '@eidu/form'
import actionLabel from '../../domain/permission/actionLabel'
import GlobalAction = com.eidu.sharedlib.entity.permission.GlobalAction
import Permission = com.eidu.sharedlib.entity.permission.Permission
import GlobalPermission = com.eidu.sharedlib.entity.permission.GlobalPermission
import ALL_ACTIONS = com.eidu.sharedlib.entity.permission.ALL_ACTIONS
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import EntityPermission = com.eidu.sharedlib.entity.permission.EntityPermission
import Action = com.eidu.sharedlib.entity.permission.Action
import EntityAction = com.eidu.sharedlib.entity.permission.EntityAction
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import SelectOptionInput from '../entity/selector/SelectOptionInput'
import SelectorEditor from '../entity/selector/SelectorEditor'

export type PermissionInputProps = {
  entityTypes: readonly EntityType[]
  permission: Permission
  onAdd: () => void
  onChange: (updatedPermission: Permission) => void
  onDelete: () => void
}

const PermissionInput: React.FC<PermissionInputProps> = ({
  entityTypes,
  permission,
  onAdd,
  onChange,
  onDelete,
}: PermissionInputProps) => {
  const [action, setAction] = useState<Action>(permission.action)
  const [targets, setTargets] = useState<EntitySelector>(
    permission instanceof EntityPermission ? permission.targets : EntitySelector.All
  )

  useEffect(() => {
    if (action instanceof GlobalAction) onChange(new GlobalPermission(action))
    else if (action instanceof EntityAction) onChange(new EntityPermission(action, targets))
  }, [action, targets])

  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" spacing={2} alignItems="center">
        <SelectOptionInput
          availableOptions={Array.from(ALL_ACTIONS.asJsReadonlySetView()).map((actionValue) => ({
            [actionLabel(actionValue)]: actionValue,
          }))}
          selected={action}
          setSelected={setAction}
        />
        {action instanceof EntityAction && (
          <SelectorEditor selector={targets} onChange={setTargets} entityTypes={entityTypes} />
        )}
        <IconButton onClick={onDelete}>
          <Delete color="error" />
        </IconButton>
        <IconButton onClick={onAdd}>
          <Add color="primary" />
        </IconButton>
      </Stack>
    </Box>
  )
}

export type PermissionWithId = {
  id: string
  permission: Permission
}

export type PermissionsInputProps = {
  entityTypes: readonly EntityType[]
  selected: readonly PermissionWithId[]
  onSelectedChange: (permissions: readonly PermissionWithId[]) => void
}

const PermissionsInput = ({ entityTypes, selected, onSelectedChange }: PermissionsInputProps) => {
  const defaultPermission = new GlobalPermission(GlobalAction.values()[0])
  return (
    <Stack direction="column" alignItems="flex-start" spacing={2} sx={{ p: 2 }}>
      {selected.length === 0 && (
        <IconButton
          onClick={() =>
            onSelectedChange(
              selected.toSpliced(0, 0, {
                id: crypto.randomUUID(),
                permission: defaultPermission,
              })
            )
          }
        >
          <Add color="primary" />
        </IconButton>
      )}
      {[...selected].map((permission, idx) => (
        <PermissionInput
          key={permission.id}
          entityTypes={entityTypes}
          permission={permission.permission}
          onAdd={() =>
            onSelectedChange(
              selected.toSpliced(idx + 1, 0, {
                id: crypto.randomUUID(),
                permission: defaultPermission,
              })
            )
          }
          onChange={(newValue) =>
            onSelectedChange(
              selected.toSpliced(idx, 1, {
                id: selected[idx].id,
                permission: newValue,
              })
            )
          }
          onDelete={() => onSelectedChange(selected.toSpliced(idx, 1))}
        />
      ))}
    </Stack>
  )
}

export default PermissionsInput
