import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

export enum SupportedEntitySelectorType {
  All,
  FieldValueEquals,
  FieldValueEqualsUserFieldValue,
  FieldValueEqualsUserEntityId,
}

type EntitySelectorTypeProps = {
  optionsWithLabels: Map<SupportedEntitySelectorType, string>
  selected: SupportedEntitySelectorType
  setSelected: (type: SupportedEntitySelectorType) => void
  disabled?: boolean
}

const EntitySelectorTypeInput = ({ optionsWithLabels, selected, setSelected, disabled }: EntitySelectorTypeProps) => (
  <FormControl sx={{ minWidth: 200 }}>
    <InputLabel id="entity-selector-type-input">Selector</InputLabel>
    <Select
      labelId="entity-selector-type-label"
      value={selected}
      label="Selector"
      onChange={(event) => setSelected(event.target.value as SupportedEntitySelectorType)}
      disabled={disabled}
    >
      {Array.from(optionsWithLabels.entries()).map(([type, label]) => (
        <MenuItem key={label} value={type}>
          {label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default EntitySelectorTypeInput
