import { com } from '@eidu/form'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import EntityWithLabelAndRelated from '../../../domain/entity/EntityWithLabelAndRelated'
import { findEntityTypeByFieldId, findFieldById } from '../../../util/EntityTypeExtensions'
import fieldValueLabel from '../../../util/FieldValueExtensions'

const entitySelectorLabel = (
  selector: EntitySelector,
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>,
  entitiesByTypeId: ReadonlyMap<EntityTypeId, ReadonlyMap<EntityId, EntityWithLabelAndRelated>>
): string => {
  if (EntitySelector.All.equals(selector)) return 'All'
  if (selector instanceof EntitySelector.OfType)
    return `type = ${entityTypes.get(selector.typeId)?.name ?? `Unknown type (${selector.typeId})`}`
  if (selector instanceof EntitySelector.FieldValueEquals) {
    const fallbackLabel = `Field id = ${selector.fieldId}`
    const types = [...entityTypes.values()]

    const entityType = findEntityTypeByFieldId(types, selector.fieldId)
    if (!entityType) return fallbackLabel

    const field = findFieldById(entityType, selector.fieldId)
    if (!field) return fallbackLabel

    const fieldName = field.name
    const fieldValueString = fieldValueLabel(field, selector.value, entitiesByTypeId) ?? '(loading...)'

    return `whose field ${fieldName} = ${fieldValueString}`
  }
  if (selector instanceof EntitySelector.FieldValueEqualsUserEntityId) {
    const fallbackLabel = `Field id ${selector.fieldId} = user`
    const types = [...entityTypes.values()]

    const entityType = findEntityTypeByFieldId(types, selector.fieldId)
    if (!entityType) return fallbackLabel

    const field = findFieldById(entityType, selector.fieldId)
    if (!field) return fallbackLabel

    return `that reference the user in field ${entityType.name}.${field.name}`
  }
  if (selector instanceof EntitySelector.FieldValueEqualsUserFieldValue) {
    const fallbackLabel = `Field id ${selector.fieldId} = User field id ${selector.userFieldId}`
    const types = [...entityTypes.values()]

    const entityType = findEntityTypeByFieldId(types, selector.fieldId)
    if (!entityType) return fallbackLabel

    const userEntityType = findEntityTypeByFieldId(types, selector.userFieldId)
    if (!userEntityType) return fallbackLabel

    const field = findFieldById(entityType, selector.fieldId)
    if (!field) return fallbackLabel

    const userField = findFieldById(userEntityType, selector.userFieldId)
    if (!userField) return fallbackLabel

    return `whose field ${entityType.name}.${field.name} = user field ${userEntityType.name}.${userField.name}`
  }
  if (selector instanceof EntitySelector.And)
    return [...selector.selectors.asJsReadonlyArrayView()]
      .map((it) => entitySelectorLabel(it, entityTypes, entitiesByTypeId))
      .join(' AND ')
  if (selector instanceof EntitySelector.Or)
    return [...selector.selectors.asJsReadonlyArrayView()]
      .map((it) => entitySelectorLabel(it, entityTypes, entitiesByTypeId))
      .join(' OR ')
  if (selector instanceof EntitySelector.Not)
    return `NOT ${entitySelectorLabel(selector.selector, entityTypes, entitiesByTypeId)}`
  return selector.toString()
}

export default entitySelectorLabel
