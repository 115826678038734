import { useContext, useEffect, useMemo, useState } from 'react'
import { FormControl, Stack, TextField } from '@mui/material'
import { com } from '@eidu/form'
import { EntityFormField } from '../EntityForm'
import { draftFieldValueToFieldValue, fieldValueToDraftFieldValue } from '../../../domain/entity/DraftFieldValue'
import { EntityTypesContext } from '../../../io/context/EntityTypes'
import FieldSingleSelect from './FieldSingleSelect'
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import Field = com.eidu.sharedlib.entity.field.Field
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import { findFieldByIdOrFirst, fieldsOfEntityType } from '../../../util/EntityTypeExtensions'

type FieldValueEqualsInputProps = {
  entityTypeId: EntityTypeId
  selector: EntitySelector.FieldValueEquals | undefined
  setSelector: (selector: EntitySelector.FieldValueEquals) => void
  disabled: boolean
}

type ValueControlProps = {
  field: Field
  value: FieldValue | undefined
  setValue: (value: FieldValue) => void
  entityTypes: ReadonlyMap<EntityTypeId, EntityType | null>
}

const ValueControl = ({ field, value, setValue, entityTypes }: ValueControlProps) => (
  <FormControl sx={{ minWidth: 200 }}>
    {field && (
      <EntityFormField
        key={`selector-value-${field.id.asString()}`}
        field={field}
        fieldValue={field && value ? fieldValueToDraftFieldValue(field.id, value) : undefined}
        entityTypes={entityTypes}
        updateField={(draftFieldValue) => setValue(draftFieldValueToFieldValue(draftFieldValue))}
      />
    )}
  </FormControl>
)

const FieldValueEqualsInput = ({ entityTypeId, selector, setSelector, disabled }: FieldValueEqualsInputProps) => {
  const entityTypeRepository = useContext(EntityTypesContext)
  const entityTypes = entityTypeRepository.useAll()
  const entityType = entityTypeRepository.useItem(entityTypeId)

  const [localFieldId, setLocalFieldId] = useState<FieldId | undefined>(selector?.fieldId)
  const field = useMemo(() => findFieldByIdOrFirst(entityType ?? undefined, localFieldId), [entityType, localFieldId])
  useEffect(() => {
    if (field && selector) {
      setSelector(selector)
    }
  }, [localFieldId, selector])

  return (
    entityType &&
    entityTypes && (
      <Stack direction="row" spacing={2}>
        <FieldSingleSelect
          label="Field"
          options={fieldsOfEntityType(entityType)}
          entityType={entityType}
          selected={field}
          setSelected={(newField) => setLocalFieldId(newField.id)}
          disabled={disabled}
        />
        {!disabled && field ? (
          <ValueControl
            entityTypes={entityTypes}
            field={field}
            value={selector?.value}
            setValue={(newValue) => setSelector(new EntitySelector.FieldValueEquals(field.id, newValue))}
          />
        ) : (
          <TextField disabled={disabled} />
        )}
      </Stack>
    )
  )
}

export default FieldValueEqualsInput
