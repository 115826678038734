import { Stack, TextField, FormControlLabel, Switch, FormGroup, Checkbox, Typography } from '@mui/material'
import { com, kotlin } from '@eidu/form'
import UserDropDown = com.eidu.sharedlib.form.definition.UserDropDown
import UserRole = com.eidu.sharedlib.form.definition.UserRole
import EiduUserRole = com.eidu.sharedlib.form.definition.EiduUserRole
import IwUserRole = com.eidu.sharedlib.form.definition.IwUserRole
import KtList = kotlin.collections.KtList
import { withKey } from '../../util/withKey'

type UserDropDownItemProps = {
  item: UserDropDown
  onChange?: (item: UserDropDown) => void
}

const UserDropDownItem: React.FC<UserDropDownItemProps> = ({ item, onChange }) => {
  const allRoles = [
    { role: EiduUserRole.Admin, label: 'Admin' },
    { role: EiduUserRole.EcdOfficer, label: 'ECD Officer' },
    { role: EiduUserRole.SubCountyEcdOfficer, label: 'Sub-County ECD Officer' },
    { role: EiduUserRole.EiduCoach, label: 'EIDU Coach' },
    { role: EiduUserRole.EiduOfficer, label: 'EIDU Officer' },
    { role: IwUserRole.FieldOfficer, label: '[IW] Field Officer' },
    { role: IwUserRole.ProgramManager, label: '[IW] Program Manager' },
    { role: IwUserRole.ProjectManager, label: '[IW] Project Manager' },
    { role: IwUserRole.RegionalManager, label: '[IW] Regional Manager' },
    { role: IwUserRole.Supervisor, label: '[IW] Supervisor' },
    { role: IwUserRole.TechnicalSpecialist, label: '[IW] Technical Specialist' },
  ]

  const handleRoleToggle = (toggledRole: UserRole) => {
    const currentRoles = item.roles.asJsReadonlyArrayView()
    const newRoles = currentRoles.includes(toggledRole)
      ? currentRoles.filter((role) => role !== toggledRole)
      : [...currentRoles, toggledRole]

    onChange?.(
      withKey(
        item.copy(
          item.prompt,
          item.key,
          KtList.fromJsArray(newRoles),
          item.required,
          item.unfilledText,
          item.placeholder
        ),
        item
      )
    )
  }

  return (
    <Stack
      spacing={2}
      sx={{ p: 1, backgroundColor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}
    >
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <TextField
          fullWidth
          label="Prompt"
          value={item.prompt}
          onChange={(e) =>
            onChange?.(
              withKey(
                item.copy(e.target.value, item.key, item.roles, item.required, item.unfilledText, item.placeholder),
                item
              )
            )
          }
          variant="standard"
          disabled={!onChange}
        />
        <TextField
          label="Key"
          value={item.key}
          onChange={(e) =>
            onChange?.(
              withKey(
                item.copy(item.prompt, e.target.value, item.roles, item.required, item.unfilledText, item.placeholder),
                item
              )
            )
          }
          variant="standard"
          disabled={!onChange}
        />
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          fullWidth
          label="Unfilled Text"
          value={item.unfilledText ?? ''}
          onChange={(e) =>
            onChange?.(
              withKey(
                item.copy(item.prompt, item.key, item.roles, item.required, e.target.value, item.placeholder),
                item
              )
            )
          }
          variant="standard"
          disabled={!onChange}
        />
        <TextField
          fullWidth
          label="Placeholder"
          value={item.placeholder ?? ''}
          onChange={(e) =>
            onChange?.(
              withKey(
                item.copy(item.prompt, item.key, item.roles, item.required, item.unfilledText, e.target.value),
                item
              )
            )
          }
          variant="standard"
          disabled={!onChange}
        />
        <FormControlLabel
          control={
            <Switch
              checked={item.required}
              onChange={(e) =>
                onChange?.(
                  withKey(
                    item.copy(item.prompt, item.key, item.roles, e.target.checked, item.unfilledText, item.placeholder),
                    item
                  )
                )
              }
              disabled={!onChange}
            />
          }
          label="Required"
        />
      </Stack>

      <Stack spacing={1}>
        <Typography variant="subtitle2">Allowed Roles</Typography>
        <FormGroup>
          <Stack direction="row" flexWrap="wrap">
            {allRoles.map(({ role, label }) => (
              <FormControlLabel
                key={label}
                control={
                  <Checkbox
                    checked={item.roles.asJsReadonlyArrayView().includes(role)}
                    onChange={() => handleRoleToggle(role)}
                    disabled={!onChange}
                  />
                }
                label={label}
                sx={{ width: '250px' }}
              />
            ))}
          </Stack>
        </FormGroup>
      </Stack>
    </Stack>
  )
}

export default UserDropDownItem
