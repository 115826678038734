import { Stack, Typography } from '@mui/material'
import { com } from '@eidu/form'
import React from 'react'
import PermissionsInput, { PermissionWithId } from './PermissionsInput'
import SelectorEditor from '../entity/selector/SelectorEditor'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector

type PermissionGrantFormProps = {
  entityTypes: readonly EntityType[]
  userSelector: EntitySelector
  setUserSelector: (selector: EntitySelector) => void
  selectedPermissions: readonly PermissionWithId[]
  setSelectedPermissions: (permissions: readonly PermissionWithId[]) => void
}

const PermissionGrantForm = ({
  entityTypes,
  userSelector,
  setUserSelector,
  selectedPermissions,
  setSelectedPermissions,
}: PermissionGrantFormProps) => (
  <Stack padding={0} spacing={3} sx={{ width: '100%', overflowX: 'auto' }}>
    <Typography variant="h5">Users</Typography>
    <SelectorEditor selector={userSelector} onChange={setUserSelector} entityTypes={entityTypes} />

    <Typography variant="h5">Actions</Typography>
    <PermissionsInput
      entityTypes={entityTypes}
      selected={selectedPermissions}
      onSelectedChange={setSelectedPermissions}
    />
  </Stack>
)

export default PermissionGrantForm
