import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { com } from '@eidu/form'
import Field = com.eidu.sharedlib.entity.field.Field
import fieldIdFromString = com.eidu.sharedlib.entity.field.fieldIdFromString
import { findFieldById } from '../../../util/EntityTypeExtensions'

type FieldSingleSelectProps = {
  label: string
  options: readonly Field[]
  selected: Field | undefined
  setSelected: (field: Field) => void
  entityType: com.eidu.sharedlib.entity.type.EntityType
  disabled: boolean
}

const FieldSingleSelect = ({ label, options, selected, setSelected, entityType, disabled }: FieldSingleSelectProps) => (
  <FormControl sx={{ minWidth: 200 }} disabled={disabled}>
    <InputLabel id="field">{label}</InputLabel>
    <Select
      labelId="field-label-id"
      value={selected?.id.asString() ?? ''}
      disabled={disabled}
      label={label}
      onChange={(event) => {
        const selectedField = findFieldById(entityType, fieldIdFromString(event.target.value))
        if (selectedField) {
          setSelected(selectedField)
        }
      }}
    >
      {options.map((entityField) => (
        <MenuItem key={entityField.name} value={entityField.id.asString()}>
          {entityField.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default FieldSingleSelect
