import { useContext, useEffect, useMemo, useState } from 'react'
import { Stack } from '@mui/material'
import { com } from '@eidu/form'
import FieldSingleSelect from './FieldSingleSelect'
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import { findFieldByIdOrFirst, fieldsOfEntityType } from '../../../util/EntityTypeExtensions'
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import { EntityTypesContext } from '../../../io/context/EntityTypes'

type FieldValueEqualsInputProps = {
  entityTypeId: EntityTypeId
  selector: EntitySelector.FieldValueEqualsUserEntityId | undefined
  setSelector: (selector: EntitySelector.FieldValueEqualsUserEntityId) => void
  disabled: boolean
}

const FieldValueEqualsUserFieldValueInput = ({
  entityTypeId,
  selector,
  setSelector,
  disabled,
}: FieldValueEqualsInputProps) => {
  const entityTypeRepository = useContext(EntityTypesContext)
  const localEntityType = entityTypeRepository.useItem(entityTypeId)

  const [localFieldId, setLocalFieldId] = useState<FieldId | undefined>(selector?.fieldId)

  const field = useMemo(
    () => findFieldByIdOrFirst(localEntityType ?? undefined, localFieldId),
    [localEntityType, localFieldId]
  )
  useEffect(() => {
    if (field) {
      setSelector(new EntitySelector.FieldValueEqualsUserEntityId(field.id))
    }
  }, [localFieldId])

  return (
    localEntityType && (
      <Stack direction="row" spacing={2}>
        <FieldSingleSelect
          label="Field"
          options={fieldsOfEntityType(localEntityType)}
          selected={field}
          entityType={localEntityType}
          setSelected={(newField) => setLocalFieldId(newField.id)}
          disabled={disabled}
        />
      </Stack>
    )
  )
}

export default FieldValueEqualsUserFieldValueInput
