import { ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React from 'react'

export type SelectOptionInputProps<T> = {
  availableOptions: readonly { [label: string]: T }[]
  selected: T
  setSelected: (selected: T) => void
  disabled?: boolean
}

const getItemLabel = <T extends unknown>(item: { [label: string]: T }) => Object.keys(item)[0]
const getItemValue = <T extends unknown>(item: { [label: string]: T }) => item[Object.keys(item)[0]]

const SelectOptionInput = <T extends unknown>({
  availableOptions,
  selected,
  setSelected,
  disabled,
}: SelectOptionInputProps<T>) => (
  <Select
    value={availableOptions.findIndex((item) => getItemValue(item) === selected)}
    disabled={disabled}
    onChange={(event: SelectChangeEvent<number>) => {
      const newSelectedIndex = event.target.value as number
      setSelected(getItemValue(availableOptions[newSelectedIndex]))
    }}
  >
    {availableOptions.map((value, index) => (
      <MenuItem key={JSON.stringify(value)} value={index}>
        <ListItemText primary={getItemLabel(value)} />
      </MenuItem>
    ))}
  </Select>
)

export default SelectOptionInput
